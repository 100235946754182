import Appview from "./components/AppView";
import "bootstrap/dist/css/bootstrap.css"
import { useEffect, useState } from "react";
import { HashRouter as Router} from 'react-router-dom';
import "./font.css";
import "./App.css";
import useIsStandalone from "./components/hooks/useIsStandalone";
import InstallPrompt from "./components/InstallPrompt";

function App() {
  const file_api = "https://server.welearn.com.ng/api/"
  const api = "https://server.welearn.com.ng/"
  const [winWidth, setWinWidth] = useState(320)
  const isStandalone = useIsStandalone();

  function widthResizer(){
    var width = window.innerWidth
    setWinWidth(width)
  }
  useEffect(() => {
    widthResizer()
    window.addEventListener('resize', widthResizer)
    return document.removeEventListener('resize', widthResizer);
  },[])
  return (
    <div className="App">
      {!isStandalone && <InstallPrompt />}
      <Router> 
        <Appview api={api} file={file_api} winWidth={winWidth} />
      </Router>
    </div>
  );
}

export default App;
