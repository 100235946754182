import { useEffect, useRef, useState } from "react";
import { PauseFill, PlayFill, StopFill } from "react-bootstrap-icons";
import "./AudioPlayer.css";
import Button from "./Button";

function AudioPlayer(props) {
    const [audioPlay, setAudioPlay] = useState(false);
    const [audioProgressBarValue, setAudioProgressBarValue] = useState(0);
    const [audioCurrentTime, setAudioCurrentTime] = useState(0);
    const [error, setError] = useState(null); // State to handle error

    const audioGetter = useRef();

    const src = props.src;

    const minutes = Math.floor((audioCurrentTime % 3600) / 60);
    const seconds = Math.floor(audioCurrentTime % 60);

    const toggleAudio = () => {
        setAudioPlay(!audioPlay);
    };

    const audioProgressBar = (e) => {
        try {
            const userValue = e.target.value / 100;
            audioGetter.current.currentTime = (audioGetter.current.duration || 0) * userValue;

            setAudioProgressBarValue(e.target.value);
        } catch (error) {
            alert("An error occurred while updating the progress bar");
        }
    };

    const stopAudio = () => {
        audioGetter.current.currentTime = 0;
        setAudioPlay(false);
    };

    useEffect(() => {
        const audioElement = audioGetter.current;

        const handleAudioPlay = () => setAudioPlay(true);
        const handleAudioPause = () => setAudioPlay(false);
        const handleAudioEnd = () => setAudioPlay(false);
        const handleAudioLoaded = () => setAudioProgressBarValue(0);
        const handleAudioTimeUpdate = () => {
            const progress = (audioElement.currentTime / audioElement.duration) * 100;
            setAudioProgressBarValue(progress);
            setAudioCurrentTime(audioElement.currentTime);
        };
        const handleAudioError = () => {
            setError("An error occurred while loading the audio.");
            setAudioPlay(false);
        };

        if (audioElement) {
            if (audioPlay) {
                audioElement.play().catch(handleAudioError);
            } else {
                audioElement.pause();
            }

            audioElement.addEventListener("playing", handleAudioPlay);
            audioElement.addEventListener("pause", handleAudioPause);
            audioElement.addEventListener("ended", handleAudioEnd);
            audioElement.addEventListener("loadeddata", handleAudioLoaded);
            audioElement.addEventListener("timeupdate", handleAudioTimeUpdate);
            audioElement.addEventListener("error", handleAudioError);

            return () => {
                audioElement.removeEventListener("playing", handleAudioPlay);
                audioElement.removeEventListener("pause", handleAudioPause);
                audioElement.removeEventListener("ended", handleAudioEnd);
                audioElement.removeEventListener("loadeddata", handleAudioLoaded);
                audioElement.removeEventListener("timeupdate", handleAudioTimeUpdate);
                audioElement.removeEventListener("error", handleAudioError);
            };
        }
    }, [audioPlay]);

    return (
        <span className={`audioGetter secondary-color ${props.className}`}>
            <audio src={src} ref={audioGetter} />
            <Button
                name={audioPlay ? <PauseFill className="icons" /> : <PlayFill className="icons" />}
                onclick={toggleAudio}
                className={props.btnClassName}
                styles={{ border: "none", background: "transparent", boxShadow: "none" }}
                info
            />

            {(props.stopBtn === true || props.stopBtn === undefined) && (
                <Button
                    name={<StopFill className="icons" />}
                    onclick={stopAudio}
                    className={props.btnClassName}
                    styles={{ border: "none", background: "transparent", boxShadow: "none" }}
                    info
                />
            )}

            <input
                value={audioProgressBarValue}
                style={{ width: "65%" }}
                onChange={audioProgressBar}
                type={"range"}
                min={"0"}
                max={"100"}
            />
            <span className={`primary-color ${props.timeClassName}`}>
                {`${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}
            </span>
        </span>
    );
}

export default AudioPlayer;
