import { X } from "react-bootstrap-icons"
import InputMethod from "../../../../Public/InputMethod"
import Button from "../../../../Public/Button"
import TextEditor from "../TextEditors";
import { useState } from "react";
import Cookies from "js-cookie"
import ServerLoading from "../../../../Public/ServerLoading";


const NewTopic = (props) => {
    const [disabled, setDisabled] = useState(false)
    const [teachingObjectives, setTeachingObjectives] = useState(null);
    const [lessonOutlines, setLessonOutlines] = useState(null);

    const tutor_id = Cookies.get("mdjdiowdtutor")


    const uploadTopic = async (e) => {
        e.preventDefault()

        let trimmedTeachingObjectives = teachingObjectives.replace("<p></p>","").replace("<ul></ul>","").replace('<ol type="1"><li></li></ol>','')
        let trimmedLessonOutlines = lessonOutlines.replace("<p></p>","").replace("<ul></ul>","").replace('<ol type="1"><li></li></ol>','')

        if (trimmedTeachingObjectives!=="" && trimmedLessonOutlines!=="" && e.target.topic_classroom) {
            props.send_class_msg(tutor_id, "1", e.target.topic_classroom.value, teachingObjectives, lessonOutlines, props.room_id )
            props.hideToolType()
        } else {
            alert("All Field are required!")
        }

    }

    return (
        <>
            <ServerLoading display={disabled} />
            <div className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <span style={{fontWeight: "bolder", textTransform: "uppercase"}}>Create New {props.room_name} Topic</span>
                        <Button className="canc_btn" onclick={() => props.hideToolType()} name={<X />} error />
                    </div>
                    <form onSubmit={uploadTopic}>
                    <div className="tool_holder_body">
                        {
                            props.type==="0"
                            ?
                            <InputMethod placeholder={"Topic"} name="topic_classroom" />
                            :
                            <select>
                                <option>
                                    Topic
                                </option>
                            </select>
                        }
                        {
                            props.type==="0"
                            ?
                            <div className="form-group">
                                <label style={{fontSize: "16px", fontWeight: "bold"}}>
                                    Teaching Objectives:
                                </label>
                                <TextEditor option={{options:['inline','history']}} setStorage={setTeachingObjectives} />
                            </div>
                            :
                            <select>
                                <option>
                                    Title
                                </option>
                            </select>
                        }
                        <div className="form-group">
                            <label style={{fontSize: "16px", fontWeight: "bold"}}>
                                Lesson Outlines:
                            </label>
                            <TextEditor option={{options:['inline','history']}} setStorage={setLessonOutlines} />
                        </div>
                    </div>
                    <div className="tool_holder_footer">
                        <Button name="Send" form/>
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default NewTopic