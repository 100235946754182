import Classholder from "./subcomponents/Classholder";

const ClassConversation = (props) => {

    return (
        <div className="bg_container text_color" style={{
            
        }}>
            <div style={{
            }}>
                <h3 style={{
                    padding: "4px 12px",
                    paddingTop: "14px", 
                    fontWeight: "bolder"
                }} className="primary_text">
                    YOUR ASSIGNED CLASS ROOM
                </h3>
            </div>
            <div style={{
                overflowY: "scroll",
                height: "87%",
                overflowX: "hidden"
            }}>
                {
                    !props.classes
                    ?
                    <span style={{padding: "12px", fontStyle: "italic", fontSize: "14px"}}>
                        You are not in a Class room yet.
                    </span>
                    :
                    props.classes.length>0
                    ?
                    props.classes.map((clax, key) => {
                        return (
                            <div key={key} >
                                <Classholder data={clax} api={props.api} file={props.file} showClassConv={props.showClassConv} />
                            </div>
                        )
                    })
                    :
                    <>
                        No Classes available for you yet. Contact Admin
                    </>
                }
                <br/>
                <br/>
                <br/>
            </div>
            
        </div>
    )
}

export default ClassConversation;