import { X } from "react-bootstrap-icons"
import Button from "../pages/components/Public/Button"

const Policy = (props) => {
    return (
        <div> 
        <div style={{
            display: props.display?"block": "none",
            position: "fixed",
            zIndex: "9999999",
            width: "100%"
        }} className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <h3 style={{fontWeight: "bolder", textTransform: "uppercase", textAlign: "center"}}> WeLearn Classroom </h3>
                        <Button className="canc_btn" onclick={() => props.setDisplay(false)} name={<X />} error />
                    </div>
                    <div className="tool_holder_body" style={{textAlign: "justify"}}>
                        <b>
                            What you should know or Policy for Facilitators 
                        </b>
                        <ol className="class_policy_list">
                            <li>
                                Must be professional in instructional delivery. Contents must be concise and precise in line with the syllabus template. Moreso you must provide learners with per module cbt or theory evaluation, evaluation for each topic covered, add u-tube link for further studies as the case may be
                            </li>

                            <li>
                                Must be prompt to discharge teaching and other allied duties. No excuse is condoned for kicking off classes late as scheduled on the timetable. Violation of this policy could result to automatic deactivation from the platform. Should you anticipate any challenge, you are to call the tutor admin few days ahead and then negotiate a replacement with another tutor to stand in for your temporary absence. Our consistency and time integrity is our asset that must not be compromised
                            </li>

                            <li>
                                Facilitators don’t have any other business or relationship with the students online apart from to teach them. Tutors are to refer students to the admins for any form of needs or further guidance
                            </li>

                            <li>
                                Facilitators are to strictly follow the instructional model embedded in the app classroom. Hence you are to have a proactive class plan to capture the instructional model. Tutors will be deactivated immediately who fail to implement the model on the platform. In the course of your orientation after verification of application, all tutors will be empowered on how to deploy the app tools and implement the weLearn teaching model
                            </li>

                            <li>
                                Supply a valid passport or selfie and account details for your payments monthly or once in two weeks as the case may be by the financial admin.
                            </li>

                            <li>
                                Upon verification by a tutor admin, all intending facilitators are to undergo a compulsory online or physical interview and take a short course training on 21st century digital instructional techniques for the African learners with weLearn Instructional technologists before authorization to teach online 
                            </li>

                            <li>
                                Any emerging challenges encountered by tutors are welcome to be listened to by our team. Furthermore the tutors are expected to come up with recommendations to improve the system as tutors are key stake holders.
                            </li>

                            <li>
                                Tutors are to earn commission credited to their account upon using weLearn referral code to have students registered on the platform.
                            </li>
                        </ol>

                        <i>Learning App built with you in mind!</i>
                    </div>
                    <div className="tool_holder_footer">
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Policy