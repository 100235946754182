import ViewTextEditor from "../ViewTextEditor"
import Cookies from "js-cookie"
import ViewTopic from "./ViewTopic"

const ViewClassRoomMsg = (props) => {
    const tutor_id = Cookies.get("mdjdiowdtutor")||""

    return (
        <div style={{
            width: "100%",
            height: "fit-content",
            position: "relative",
            clear: "both"
        }}>
            <div style={{
                position: "relative",
                float: props.msg.tutor_id === tutor_id ? "right" : "left",
                background: props.msg.tutor_id === tutor_id ? "#fff" : "#12529f",
                color: props.msg.tutor_id === tutor_id ? "#12529f" : "#fff",
                padding: "12px",
                paddingBottom: "18px",
                width: "fit-content",
                height: "fit-content",
                margin: "3px 4px",
                borderRadius: "4px",
                maxWidth: "500px"
            }}>
                {
                    props.msg.topic==="0"
                    ?
                    <>
                        <span style={{ fontSize: "14px", fontWeight: "bolder", fontStyle: "italic" }}>Announcement:</span> 
                        <br/> 
                        <ViewTextEditor value={props.msg.content} />
                    </>
                    :
                    <ViewTopic msg={props.msg} />
                }
                <span style={{ fontSize: "10px", fontStyle: "italic", position: "absolute", bottom: "1px", left: "4px" }}>
                    {props.msg.tutor_id === tutor_id ? (
                        props.msg.sent === 0 ? "sending" : "sent"
                    ) : (
                        " " + props.msg.tutor_id
                    )}
                </span>
            </div>
        </div>
    )
}

export default ViewClassRoomMsg