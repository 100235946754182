import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Mic,
  MicFill,
  Pause,
  PauseCircleFill,
  Stop,
  StopCircleFill,
  X,
  XCircleFill,
} from "react-bootstrap-icons";
import Button from "../../../../Public/Button";

const mimeType = "audio/webm"; // Changed to webm as browser native MediaRecorder doesn't support mp3
const MAX_RECORDING_TIME = 200; // Maximum recording time in seconds (10 seconds for testing)

const VoiceNote = (props) => {
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
	const [isRunning, setIsRunning] = useState(false)
	const [start, setStart] = useState(0)



  const minutes = Math.floor(elapsedTime / 60);
  const seconds = Math.floor(elapsedTime % 60);

	const startTime = () => {
		setIsRunning(true);
    setStart(Date.now()-(elapsedTime*1000))
	}

	const pauseTime = () => {
		setIsRunning(false);
	}

	const resetTime = () => {
		setElapsedTime(0)
		setIsRunning(false)
	}

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setPermission(true);
        setStream(mediaStream);
        return mediaStream;
      } catch (err) {
        alert("Please enable microphone permissions to start recording.");
        setPermission(false);
        return null;
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
      return null;
    }
  };

  const startRecording = async () => {
    const mediaStream = stream || await getMicrophonePermission();
    if (!mediaStream) return;

    setRecordingStatus("recording");
    props.showAudio(false)
    const media = new MediaRecorder(mediaStream, { mimeType });

    mediaRecorder.current = media;

    mediaRecorder.current.start();
    startTime()
    let localAudioChunks = [];

    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;

      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);

  };

  const pauseRecording = () => {
    setRecordingStatus("paused");
    mediaRecorder.current.pause();
    pauseTime();
  };

  const resumeRecording = () => {
    setRecordingStatus("recording");
    mediaRecorder.current.resume();
		startTime()
  };

  const stopRecording = useCallback(() => {
    setRecordingStatus("inactive");
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }
    resetTime()

    if (mediaRecorder.current) {
      mediaRecorder.current.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: mimeType });
        const audioUrl = URL.createObjectURL(audioBlob);

        props.audioState(audioUrl);
        props.audioUploadState(audioBlob);

        setAudioChunks([]);
        if (stream) {
          stream.getTracks().forEach(track => track.stop()); // Stop all tracks to release the mic
          setStream(null); // Clear the stream state
        }
      };
    }

    setPermission(false); // Reset permission to hide the mic icon
    props.showAudio(true);
  }, [audioChunks, props, stream, intervalId]);

  const cancel = () => {
    setPermission(false);
    if (stream) {
      stream.getTracks().forEach(track => track.stop()); // Stop all tracks to release the mic
      setStream(null); // Clear the stream state
    }
    props.showAudio(true);
  };

  useEffect(() => {

    if (isRunning) {
      const id = setInterval(() => {
        const newElapsedTime = Math.floor((Date.now() - start) / 1000);
        setElapsedTime(newElapsedTime);
        if (newElapsedTime >= MAX_RECORDING_TIME) {
          stopRecording();
        }
      }, 1000);
      setIntervalId(id);
    } else {
      clearInterval(intervalId)
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  return (
    <>
      {recordingStatus === "inactive" && !permission ? (
          <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden"}} onclick={() => startRecording()} name={
            <>
              <Mic style={{fontSize: "20px", padding: "2px"}} />
              <br/>
              <span>Record</span>
            </>
          } />
        ) : null}

      <>
        {recordingStatus === "recording" ? (
          <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden"}} onclick={() => pauseRecording()} name={
            <>
              <Pause style={{fontSize: "20px", padding: "2px"}} />
              <br/>
              <span>Pause</span>
            </>
          } />
        ) : null}

        {recordingStatus === "paused" ? (
          <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden"}} onclick={() => resumeRecording()} name={
            <>
              <Mic style={{fontSize: "20px", padding: "2px"}} />
              <br/>
              <span>Resume</span>
            </>
          } />
        ) : null}

        {permission ? (
          <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden"}} name={
            <span>
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}/03:20
            </span>
          } info />
        ) : (
          ""
        )}

        {recordingStatus === "inactive" && permission ? (
          <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden"}} onclick={() => cancel()} name={
            <>
              <X style={{fontSize: "20px", padding: "2px"}} />
              <br/>
              <span>Cancel</span>
            </>
          } />
        ) : null}

        {(recordingStatus === "recording" || recordingStatus === "paused") && permission ? (
          <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden"}} onclick={() => stopRecording()} name={
            <>
              <Stop style={{fontSize: "20px", padding: "2px"}} />
              <br/>
              <span>End</span>
            </>
          } />
        ) : null}
      </>
    </>
  );
};

export default VoiceNote;
