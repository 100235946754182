import React, { useState, useEffect } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const TextEditors = (props) => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    props.setStorage(html);
  }, [editorState, props]);


  return (
    <>
      <Editor
          editorState={editorState}
          onEditorStateChange={setEditorState}
          wrapperClassName={`wrapper-class ${props.wrapperClassName}`}
          editorClassName={`editor-class`}
          toolbarClassName={`toolbar-class`}
          toolbar={props.option} 
          toolbarStyle={props.style}
        />
      {/* {
          options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove','history'],
      } */}
      
    </>
  )
}

export default TextEditors