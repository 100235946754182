import { Trash, Headphones, Easel, FileMusic, FilePdf, Files, Images, Link, Mic, X, CameraVideo, Plus } from "react-bootstrap-icons"
import Button from "../../../../Public/Button"
import TextEditor from "../TextEditors";
import { useCallback, useEffect, useRef, useState } from "react";
import WhiteBoard from "../../../../Public/WhiteBoard";
import VoiceNote from "./VoiceNote";
import AudioPlayer from "../../../../Public/AudioPlayer";
import LinkComponent from "../../../../Public/LinkComponent";
import Cookies from "js-cookie"
import axios from "axios";

const TeachingTool = (props) => {
    const [rmTopic, setRmTopic] = useState([])
    const [teachText, setTeachText] = useState(null); 
    const [whiteBoard, setWhiteBoard] = useState([])
    const [showAudio, setShowAudio] = useState(true)
    const [audioFile, addAudioFile] = useState("")
    const [uploadAudio, setUploadAudio] = useState([])
    const [showMusicUploader, setShowMusicUploader] = useState(false)
    const [documentName, setDocumentName] = useState("")
    const [documentFile, setDocumentFile] = useState([])
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [videoURL, setVideoURL] = useState("");
    const [showLink, setShowLink] = useState(false)
    const [link, setLink] = useState('');
    const [links, setLinks] = useState([]);
    const [disabled, setDisabled] = useState(false)
    const [upload_rate, setUpload_rate] = useState(0)
 
    const tutor_id = Cookies.get("mdjdiowdtutor")

    const topicVal = useRef(null)
    const lessonVal = useRef(null)
    const doc_file_input = useRef(null)
    const videoRef = useRef(null);

    const addLink = () => {
        setLinks([...links, link]);
        setLink('');
    };    

    const deleteLink = (linkToDelete) => {
        setLinks(links.filter(link => link !== linkToDelete));
    };    

    const setMicAudio = (url) => {
        addAudioFile(url)
    }

    const _setShowAudio = (value) => {
        setShowAudio(value)
    }

    const setMicAudioUpload = (file) => {
        setUploadAudio(file)
    }

    const clrAudio = () =>{ 
        addAudioFile("")
        setUploadAudio("")
    }

    
    const generateUniqueId = () => {
        return Math.floor(Math.random() * 1000000); // Adjust range as needed
    };

    const setImgURL = (id, imgSrc) => {
        setWhiteBoard(prevState =>
            prevState.map(item => (item.id === id ? { ...item, imgSrc } : item))
        );
    };

    const addNewImg = () => {
        setWhiteBoard(prevState => [
            ...prevState,
            {
                id: generateUniqueId(), // Assuming IDs are sequential
                imgSrc: null
            }
        ]);
    };

    const removeImg = (id) => {
        setWhiteBoard(prevState =>
            prevState.filter(item => item.id !== id)
        );
    };

    const imageUpload = (event) => {
        const files = Array.from(event.target.files);
        let validFiles = [];
    
        files.forEach((file) => {
          if (file.size <= (3 * 1024 * 1024)) {
            validFiles.push(file);
          } else {
            console.log(`File size exceeds the limit of ${3} MB: ${file.name}`);
          }
        });
    
        const totalFiles = selectedImages.length + validFiles.length;
        if (totalFiles > 10) {
          console.log(`You can only upload a maximum of ${10} files.`);
          validFiles = validFiles.slice(0, 10 - selectedImages.length);
        }
    
        setSelectedImages([...selectedImages, ...validFiles]);
        event.target.value = "";
    };

    const removeImage = (index) => {
        const updatedFiles = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(updatedFiles);
      };

    useEffect(() => {
        //get all subject topic in this room
        const storedData = sessionStorage.getItem('class_msg');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            const jsonArr = parsedData
            const sortArrAs = jsonArr.sort((a, b) => b.date - a.date)
            setRmTopic(sortArrAs.filter(topic => (topic.class_id.trim()===props.room_id.trim())&&(Number(topic.topic)===1)))
        }
    }, [props.room_id])

    const audioSetter = async (event) => {
        let element = event.target.files[0];
        const reader = new FileReader();
        const MAX_SIZE_MB = 7;
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
      
        if (element.size > MAX_SIZE_BYTES) {
          console.log(`File size exceeds the limit of ${MAX_SIZE_MB} MB.`);
          event.target.value = "";
          return;
        }
      
        reader.addEventListener("load", async () => {
          if (element.type === "audio/mp3" || element.type === "audio/ogg" || element.type === "audio/wav" || 
              element.type === "audio/mpeg" || element.type === "audio/webm" || element.type === "audio/m4a" || 
              element.type === "audio/x-m4a") {
      
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const arrayBuffer = await fetch(reader.result).then(res => res.arrayBuffer());
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
      
            // Downsample the audio (reduce the sample rate to lower the quality)
            const offlineAudioContext = new OfflineAudioContext(
              audioBuffer.numberOfChannels,
              audioBuffer.duration * 16000, // 16000 Hz target sample rate
              16000 // target sample rate
            );
      
            const bufferSource = offlineAudioContext.createBufferSource();
            bufferSource.buffer = audioBuffer;
            bufferSource.connect(offlineAudioContext.destination);
            bufferSource.start();
      
            const renderedBuffer = await offlineAudioContext.startRendering();
            const reducedQualityBlob = await bufferToBlob(renderedBuffer);
      
            const reducedQualityUrl = URL.createObjectURL(reducedQualityBlob);
            addAudioFile(reducedQualityUrl);
            setUploadAudio(reducedQualityBlob);
          } else {
            alert("File not` supported " + element.type);
          }
          event.target.value = "";
        }, false);
      
        reader.readAsDataURL(element);
      
        const bufferToBlob = async (audioBuffer) => {
          const waveArray = audioBufferToWav(audioBuffer);
          return new Blob([waveArray], { type: 'audio/wav' });
        };
      
        const audioBufferToWav = (buffer) => {
          const numOfChan = buffer.numberOfChannels,
                length = buffer.length * numOfChan * 2 + 44,
                bufferLength = buffer.length,
                result = new ArrayBuffer(length),
                view = new DataView(result),
                channels = [];
          let i, sample, offset = 0, pos = 0;
      
          setUint32(0x46464952); // "RIFF"
          setUint32(length - 8); // file length - 8
          setUint32(0x45564157); // "WAVE"
      
          setUint32(0x20746d66); // "fmt " chunk
          setUint32(16); // length = 16
          setUint16(1); // PCM (uncompressed)
          setUint16(numOfChan);
          setUint32(buffer.sampleRate);
          setUint32(buffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
          setUint16(numOfChan * 2); // block-align
          setUint16(16); // 16-bit (hardcoded in this demo)
      
          setUint32(0x61746164); // "data" - chunk
          setUint32(length - pos - 4); // chunk length
      
          for (i = 0; i < buffer.numberOfChannels; i++) {
            channels.push(buffer.getChannelData(i));
          }
      
          while (pos < bufferLength) {
            for (i = 0; i < numOfChan; i++) {
              sample = Math.max(-1, Math.min(1, channels[i][pos]));
              sample = sample < 0 ? sample * 0x8000 : sample * 0x7fff;
              view.setInt16(offset, sample, true);
              offset += 2;
            }
            pos++;
          }
      
          return view;
      
          function setUint16(data) {
            view.setUint16(offset, data, true);
            offset += 2;
          }
      
          function setUint32(data) {
            view.setUint32(offset, data, true);
            offset += 4;
          }
        };
      };
      
      
    const validateDocument = async (event) => {
        setDocumentName("")
        setDocumentFile([])
        try {
            let file = event.target.files[0];
            const MAX_SIZE_MB = 18; // Set max size to 10 MB
            const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
        
            if (file.size > MAX_SIZE_BYTES) {
            alert(`File size exceeds the limit of ${MAX_SIZE_MB} MB.`);
            event.target.value = "";
            return;
            }
         
            const supportedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'text/plain' // MIME type for .txt files
            ];
        
            if (!supportedTypes.includes(file.type)) {
            alert(`File type not supported: ${file.type}`);
            event.target.value = "";
            return;
            }

            setDocumentName(file.name)
            setDocumentFile(file)
            event.target.value = "";
        } catch (error) {
            event.target.value = "";
            alert("No file uploaded")
        }
    };

    const styles = {
        imageGrid: {
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(100px, 1fr))',
          gap: '10px',
          marginTop: '8px'
        },
        imageContainer: {
          position: 'relative',
          height: '100px',
          width: '100px',
          overflow: 'hidden',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        },
        image: {
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        },
        removeButton: {
          position: 'absolute',
          right: '5px',
          top: '5px',
          padding: '0px 1px',
          fontSize: '21px',
          cursor: 'pointer'
        }
    };

    const validateVideo = (event) => {
        const file = event.target.files[0];
        if (file && file.size <= 50 * 1024 * 1024) {
          const url = URL.createObjectURL(file);
          setVideoURL(url);
          setSelectedVideo(file);
        } else {
          alert(`File size exceeds the limit of ${50} MB.`);
        }

        event.target.value = "";
    };

    
    const handleVideoLoaded = () => {
        if (videoRef.current.duration > 300) {
            alert(`Video exceeds the maximum duration of ${300 / 60} minutes. Please crop the video.`);
            removeVideo()
        }
        return
    };

    const removeVideo = () => {
      setSelectedVideo(null);
      setVideoURL("");
    };

    const getFileExtension = (fileName) => {
        if (typeof fileName !== 'string' || fileName.length === 0) {
          return null;
        }
        const lastDotIndex = fileName.lastIndexOf('.');
        if (lastDotIndex > 0 && lastDotIndex < fileName.length - 1) {
          return fileName.substring(lastDotIndex + 1).toLowerCase();
        }
        return null;
    };

    function get_time() {
        return Date.now();
    }

    const generateRandomNumber = () => {
        return Math.floor(Math.random() * 90000) + 10000;
    };

    const UploadLesson = useCallback(async () => {
        const class_id = props.room_id
        const topic_id = topicVal.current?.value
        const lesson = lessonVal.current?.value
        const lessonText = teachText
        const lesson_id = get_time()+"_"+generateRandomNumber()+"_"+class_id+"_"+tutor_id
        
        //get all files
        const audio = uploadAudio //object
        const images = selectedImages //arrays
        const video = selectedVideo //object
        const document = documentFile //object
        const allLinks = links //array

        const formData = new FormData()
        setDisabled(true)

        //upload all files then send message to database
        formData.append("audio", audio)
        whiteBoard.forEach((board, index) => {
            formData.append(`whiteBoard_${index}`, JSON.stringify(board.imgSrc));
        });
        images.forEach((image, index) => {
            formData.append(`images_${index}`, image);
        });
        formData.append("video", video)
        formData.append("document", document)
        allLinks.forEach((link, index) => {
            formData.append(`allLinks[${index}]`, link);
        });
        formData.append("lesson_id", lesson_id)
        formData.append("topic_id", topic_id)
        formData.append("class_id", class_id)
        formData.append("tutor_id", tutor_id)

        const config = {
            onUploadProgress: function (progressEvent) {
                const { loaded, total } = progressEvent
                setUpload_rate(0)

                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    setUpload_rate(percent);
                } else {
                    setUpload_rate(100);
                    setDisabled(false)
                }
            }
        }

        try {
            const response = await axios.post(props.api+"tutor/upload_lesson_files", formData, config);
            try {
                if (response.data.error) {
                    console.log(response.data)
                    // alert(response.data.message)
                    setDisabled(false)
                } else {
                    // const jsonArr = response.data
                    // const sortArrAs = jsonArr.sort((a, b) => b.update_time - a.update_time)
                    // sessionStorage.setItem('tutors_classes', JSON.stringify(sortArrAs));
                    console.log(response.data)
                    setDisabled(false)
                }
            } catch (error) {
                console.log("Site under Maintenance. Try again later")
                setDisabled(false)
            }                  
        } catch (err) {
            if (err.response) {
                console.log(err.response.data.message);
                setDisabled(false)
            } else if (err.request) {
                console.log('No response from server');
                setDisabled(false)
            } else {
                console.log('Error making request');
                setDisabled(false)
            }
        }
    },[props.room_id, props.api, documentFile, links, selectedImages, selectedVideo, teachText, tutor_id, uploadAudio, whiteBoard])


    return (
        <>
            <div className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <span style={{fontWeight: "bolder", textTransform: "uppercase"}}>Add New {props.room_name} Lesson </span>
                        <Button className="canc_btn" onclick={() => props.hideToolType()} name={<X />} error />
                    </div>
                    <div className="tool_holder_body">
                        <div className="form-group">
                            <label className="form-label" style={{fontWeight: "bolder"}}>
                                Select Topic
                            </label>
                            <select className="form-control" ref={topicVal} >
                                {
                                    rmTopic.map((topic, key) => {
                                        return (
                                            <option value={topic.message_id} key={key}>
                                                {topic.content}
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="form-label" style={{fontWeight: "bolder"}} >
                                Lesson
                            </label>
                            <input name="Lesson" className="form-control" ref={lessonVal} />
                        </div>
                        <div className="form-group">
                            <label style={{fontSize: "16px", fontWeight: "bold"}}>
                                Input Text
                            </label>
                            <TextEditor setStorage={setTeachText} option={{options:['inline','history', 'emoji']}} />
                        </div>
                        <b>
                            Teaching Tools
                        </b>

                        <div className="white_board_holder">
                            {
                                whiteBoard.length>0
                                ?
                                <>
                                    {
                                        whiteBoard.map((whiteBoard, key) => {
                                            return (
                                                <div key={key} style={{marginTop: "5px"}}>
                                                    <WhiteBoard imgId={whiteBoard.id} removeImg={removeImg} setImgURL={setImgURL} winWidth={props.winWidth} />
                                                </div>
                                            )
                                        })
                                    }
                                </>
                                :
                                ""
                            }
                        </div>
                        <div className="audio" style={{
                            width: "100%",
                            textAlign: "center",
                            margin: "12px auto"
                        }}>
                            <input type="file" id="audio" name="audio" accept="audio/*" style={{display:"none"}} onChange={audioSetter}/>
                            {
                                    showMusicUploader && audioFile ===""
                                    ?
                                    <div className="audio_arr">
                                        {
                                        showAudio ? 
                                        <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden", padding: "0px"}} name={
                                            <label style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", textAlign: "center", cursor: "pointer" }} htmlFor="audio" >
                                                <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", display: "block", textAlign: "center",}}>
                                                    <Headphones style={{fontSize: "22px", padding: "2px"}}/>
                                                    <br/>
                                                    Audio File
                                                </span>
                                            </label>
                                        } />
                                        :
                                        ""
                                        }
                                        <VoiceNote audioState={setMicAudio} showAudio={_setShowAudio} audioUploadState={setMicAudioUpload}/>
                                    </div>
                                    :
                                    ""
                                }
                            {
                                audioFile!==""
                                ?
                                <div style={{
                                    width: "100%",
                                    height: "fit-content",
                                    position: "relative",
                                    overflow: "hidden",
                                    display: "flex",
                                    margin: "12px 0px"
                                }}>
                                    <AudioPlayer src={audioFile}/>
                                    <Button styles={{
                                        padding:"1px 10px",
                                        marginLeft: "3px"
                                    }} onclick={() => {
                                        if (window.confirm("delete audio?")) {
                                            clrAudio()
                                        }
                                    }} name={ <Trash /> } error />
                                </div>
                                :
                                ""
                            }
                        </div>
                        <div>
                            <input 
                                type="file" 
                                onChange={validateDocument} 
                                accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
                                id="document_file"
                                style={{display: "none"}}
                                ref={doc_file_input}
                            />
                            {documentName ? 
                            <div style={{
                                display: "flex",
                                flexWrap: "wrap",
                                border: "2px solid #12529f",
                                padding: "5px 8px",
                                maxWidth: "550px",
                                margin: "12px auto",
                                borderRadius: "8px",

                            }}>
                                <span style={{
                                    width: "50px",
                                    height: "50px",
                                    display: "block",
                                    fontSize: "10px",
                                    textAlign: "center",
                                    border: "2px solid #12529f",
                                    borderRadius: "50px",
                                    position: "relative"

                                }}>
                                    <span style={{ 
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)"
                                    }}>
                                        {getFileExtension(documentName)}
                                    </span>
                                </span> 
                                <span style={{
                                    margin: "auto 7px",
                                    fontSize: "12px"
                                }}>
                                    {documentName}
                                </span>
                            </div> 
                            : ""}
                        </div>
                        <div>
                            <input 
                                type="file" 
                                multiple 
                                onChange={imageUpload} 
                                accept="image/*"
                                id="multiple_images"
                                style={{display: "none"}}
                            />
                            <input 
                              type="file" 
                              accept="video/*" 
                              onChange={validateVideo} 
                              style={{ display: 'none' }} 
                              id="single_video"
                            />
                            <div>
                                {(selectedImages.length > 0 || selectedVideo) && (
                                <div>
                                    <b>Selected Image(s)/Video:</b>
                                    <div style={styles.imageGrid}>
                                        {selectedImages.map((file, index) => (
                                            <div key={index} style={styles.imageContainer}>
                                                <img
                                                    src={URL.createObjectURL(file)}
                                                    alt={`upload-${index}`}
                                                    style={styles.image}
                                                />
                                                <Button 
                                                    styles={styles.removeButton} 
                                                    name={<X />} 
                                                    onclick={() => removeImage(index)} 
                                                    error 
                                                />
                                            </div>
                                        ))}
                                        {selectedVideo && (
                                            <div style={styles.imageContainer}>
                                                <video 
                                                    ref={videoRef} 
                                                    src={videoURL} 
                                                    autoPlay
                                                    onLoadedMetadata={handleVideoLoaded} 
                                                    style={styles.image}
                                                />
                                                <Button 
                                                    styles={styles.removeButton} 
                                                    name={<X />} 
                                                    onclick={removeVideo} 
                                                    error 
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                        <div>
                            {
                                showLink 
                                ?
                                <div className="form-group" style={{display: "flex", margin: "12px auto"}}>
                                    <input
                                        type="text"
                                        value={link}
                                        onChange={(e) => setLink(e.target.value)}
                                        placeholder="Enter a link"
                                        className="form-control"
                                    />
                                    <Button styles={{marginLeft: "8px"}} name={
                                        <>
                                            <Plus style={{fontSize: "24px", padding: "2px"}} />
                                        </>
                                    } onclick={() => {
                                        addLink()
                                        setShowLink(false)
                                    }} />
                                </div>
                                :
                                ""
                            }
                            <div className="xsm_ele_grid" style={{margin: "12px auto"}}>
                                {links.length > 0 ? (
                                links.map((link, index) => {
                                    return (
                                        <div style={{
                                            border: "1px solid #12529f",
                                            padding: "8px 12px",
                                            position: "relative",
                                            fontSize: "12px"
                                        }} key={index} >
                                            <LinkComponent link={link} />
                                            <Button onclick={() => deleteLink(link)} styles={{ position: "absolute", right: "4px", top: "4px" }} name={
                                                <>
                                                    <Trash />
                                                </>
                                            } error />
                                            <a href={link} target="_blank" rel="noreferrer" >
                                                {link}
                                            </a>
                                        </div>
                                    )
                                })
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                        <div className="ssm_ele_grid" style={{marginTop: "28px"}}>
                            <Button onclick={()=> {
                                addNewImg()
                            }} styles={{textAlign: "center", fontSize: "10px"}} name={
                                <span >
                                    <Easel style={{fontSize: "22px", padding: "2px"}}/>
                                    <br/>
                                    Add White Board
                                </span>
                            } />
                            <Button styles={{textAlign: "center", fontSize: "10px"}} name={
                                <span >
                                    {
                                        audioFile?
                                        < >
                                            <Trash style={{fontSize: "22px", padding: "2px"}}/>
                                            <br/>
                                            Remove Audio
                                        </> :
                                        <>
                                            {
                                                showMusicUploader
                                                ?
                                                <>
                                                    <X style={{fontSize: "22px", padding: "2px"}}/>
                                                    <br/>
                                                    Hide VN
                                                </>
                                                :
                                                <>
                                                    <Mic style={{fontSize: "22px", padding: "2px"}}/>
                                                    <br/>
                                                    Voice Note
                                                </>
                                            }
                                        </>
                                    }
                                    
                                </span>
                            } onclick={() => {
                                if (audioFile) {
                                    if (window.confirm("delete audio?")) {
                                        setUploadAudio([])
                                        addAudioFile("")
                                        setShowMusicUploader(false)
                                    }
                                } else {
                                    setShowMusicUploader(!showMusicUploader)
                                }
                            }} />
                            <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden", padding: "0px", minHeight: "60px"}} name={
                                <>
                                    {
                                        !documentName
                                        ?
                                        <label style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", textAlign: "center", cursor: "pointer" }} htmlFor="document_file" >
                                            <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", display: "block", textAlign: "center",}}>
                                                <FilePdf style={{fontSize: "22px", padding: "2px"}}/>
                                                <br/>
                                                Pdf File
                                            </span>
                                        </label>
                                        :
                                        <span style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", display: "block", textAlign: "center", cursor: "pointer" }} onClick={() => {
                                            if (window.confirm("Remove document?")) {
                                                setDocumentName("")
                                                setDocumentFile([])
                                                doc_file_input.current.value=""
                                            }
                                        }} >
                                            <span style={{position: "absolute", left: "50%", top: "50%", fontSize: "10px", transform: "translate(-50%, -50%)", display: "block", textAlign: "center", lineHeight: "1"}}>
                                                <Trash style={{fontSize: "22px", padding: "2px"}}/>
                                                <br/>
                                                Remove document
                                            </span>
                                        </span>
                                    }
                                </>
                            } />
                            <Button styles={{textAlign: "center", fontSize: "10px"}} name={
                                <span >
                                    {
                                        audioFile?
                                        < >
                                            <Trash style={{fontSize: "22px", padding: "2px"}}/>
                                            <br/>
                                            Remove Audio
                                        </> :
                                        <>
                                            {
                                                showMusicUploader
                                                ?
                                                <>
                                                    <X style={{fontSize: "22px", padding: "2px"}}/>
                                                    <br/>
                                                    Hide Audio
                                                </>
                                                :
                                                <>
                                                    <FileMusic style={{fontSize: "22px", padding: "2px"}}/>
                                                    <br/>
                                                    Audio
                                                </>
                                            }
                                            
                                        </>
                                    }
                                </span>
                            } onclick={() => {
                                if (audioFile) {
                                    if (window.confirm("delete audio?")) {
                                        setUploadAudio([])
                                        addAudioFile("")
                                        setShowMusicUploader(false)
                                    }
                                } else {
                                    setShowMusicUploader(!showMusicUploader)
                                }
                            }} />
                            <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden", padding: "0px", minHeight: "60px"}} name={
                                <>
                                <label style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", textAlign: "center", cursor: "pointer" }} htmlFor="single_video" >
                                        <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", display: "block", textAlign: "center",}}>
                                            <CameraVideo style={{fontSize: "22px", padding: "2px"}}/>
                                            <br/>
                                            Video Note
                                        </span>
                                    </label>
                            </>
                            } />
                            <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden", padding: "0px", minHeight: "60px"}} name={
                                <>
                                <label style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", textAlign: "center", cursor: "pointer" }} htmlFor="multiple_images" >
                                        <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", display: "block", textAlign: "center",}}>
                                            <Images style={{fontSize: "22px", padding: "2px"}}/>
                                            <br/>
                                            Image
                                        </span>
                                    </label>
                            </>
                            } />
                            <Button styles={{textAlign: "center", fontSize: "10px"}} onclick={ () => {
                                !showLink ?
                                setShowLink(true)
                                :
                                setShowLink(false)
                            } } name={
                                <span >
                                    {
                                        !showLink ?
                                        <>
                                            <Link style={{fontSize: "22px", padding: "2px"}}/>
                                            <br/>
                                            Link
                                        </>
                                        :
                                        <>
                                            <X style={{fontSize: "22px", padding: "2px"}}/>
                                            <br/>
                                            Hide Link
                                        </>
                                    }
                                </span>
                            } />
                            <Button styles={{textAlign: "center", fontSize: "10px", position: "relative", overflow: "hidden", padding: "0px", minHeight: "60px"}} name={
                                <>
                                    {
                                        !documentName
                                        ?
                                        <label style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", textAlign: "center", cursor: "pointer" }} htmlFor="document_file" >
                                            <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", display: "block", textAlign: "center",}}>
                                                <Files style={{fontSize: "22px", padding: "2px"}}/>
                                                <br/>
                                                Document
                                            </span>
                                        </label>
                                        :
                                        <span style={{position: "relative", width: "100%", height: "100%", left: "0px", top: "0px", display: "block", textAlign: "center", cursor: "pointer" }} onClick={() => {
                                            if (window.confirm("Remove document?")) {
                                                setDocumentName("")
                                                doc_file_input.current.value=""
                                            }
                                        }} >
                                            <span style={{position: "absolute", left: "50%", top: "50%", fontSize: "10px", transform: "translate(-50%, -50%)", display: "block", textAlign: "center", lineHeight: "1"}}>
                                                <Trash style={{fontSize: "22px", padding: "2px"}}/>
                                                <br/>
                                                Remove document
                                            </span>
                                        </span>
                                    }
                                </>
                            } />
                        </div>
                    </div>
                    <div className="tool_holder_footer">
                        {
                            disabled?
                            <div style={{width: "100%", height: "fit-content", background: "rgba(18, 82, 159, .3)", position: "relative", borderRadius: "4px", overflow: "hidden", }} onClick={() => setDisabled(false)}>
                                <div style={{minWidth: "25px", width: upload_rate+"%", height: "30px", position: "relative",background: "rgba(18, 82, 159, 1)", transition: "all .3s"}}>
                                    <span style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", fontSize: "12px", transition: "all .3s", color: "#fff"}}>
                                        {upload_rate}%
                                    </span>
                                </div>
                            </div>
                            :
                            <Button name="Send" onclick={() => UploadLesson()} disabled={disabled} />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeachingTool