import { Link } from "react-router-dom";
import Cookies from "js-cookie"
import CountdownTimer from "../../Public/CountdownTimer";
import { useState } from "react";


const Dashboard = (props) => {
    const tutor_id = Cookies.get("mdjdiowdtutor")
    const [tutorDetails, settutorDetails] = useState(JSON.parse(Cookies.get("tutorDetails")))
    return (
        <div style={{padding: "10px"}} className="profile_holder">
            <div className="main_details_holder">
                <div className="my_pp">
                    <img src={props.api+tutorDetails.passport} alt="" />
                </div>
                <div style={{textAlign: "center", fontWeight: "bolder"}}>
                    {tutorDetails.firstname?.toUpperCase()} {tutorDetails.middlename?.toUpperCase()} {tutorDetails.surname?.toUpperCase()}
                </div>
                <div style={{textAlign: "center"}}>
                    {tutorDetails.email}
                </div>
                <Link to="logout" style={{
                    background: "#dc3545",
                    color: "#fff",
                    textDecoration: "none",
                    padding: "8px 14px",
                    borderRadius: "4px",
                    display: "block",
                    margin: "3px auto",
                    width: "fit-content"
                }}>Logout</Link>
            </div>
            <div className="other_details_holder">
                <div>
                    <table className="table table-striped" style={{textTransform: "capitalize"}}>
                        <thead>
                            <tr>
                                <th colSpan={2} style={{fontSize: "22px", background: "inherit"}}>
                                    Personal Details
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    Gender:
                                </td>
                                <td>
                                    {tutorDetails.gender?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Date of Birth:
                                </td>
                                <td>
                                    {tutorDetails.dob?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    WhatsApp Number:
                                </td>
                                <td>
                                    {tutorDetails.whatsapp?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Number:
                                </td>
                                <td>
                                    {tutorDetails.phone?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Name:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_name_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Phone:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_phone_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Address:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_address_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    1st Guarantor Relationship:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_occupation_1?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Name:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_name_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Phone:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_phone_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Address:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_address_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    2nd Guarantor Relationship:
                                </td>
                                <td>
                                    {tutorDetails.guarantor_occupation_2?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Type:
                                </td>
                                <td>
                                    {tutorDetails.class_type?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Class Session:
                                </td>
                                <td>
                                    {tutorDetails.class_session?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Teaching Experience:
                                </td>
                                <td>
                                    {tutorDetails.teaching_exp?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Qualification:
                                </td>
                                <td>
                                    {tutorDetails.qualifications_?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Area of Study:
                                </td>
                                <td>
                                    {tutorDetails.area_of_study?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Subject:
                                </td>
                                <td>
                                    {tutorDetails.subject?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Number:
                                </td>
                                <td>
                                    {tutorDetails.acct_number?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Name:
                                </td>
                                <td>
                                    {tutorDetails.acct_name?.toLowerCase()}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Bank Name:
                                </td>
                                <td>
                                    {tutorDetails.bank_name?.toLowerCase()}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;