import { X } from "react-bootstrap-icons"
import Button from "../../../../Public/Button"

const AssignmentTool = (props) => {
    return (
        <>
            <div className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <span style={{fontWeight: "bolder", textTransform: "uppercase"}}>Assignment Tools</span>
                        <Button className="canc_btn" onclick={() => props.hideToolType()} name={<X />} error />
                    </div>
                    <form>
                    <div className="tool_holder_body">
                        hi
                    </div>
                    <div className="tool_holder_footer">
                        <Button name="Send" />
                    </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default AssignmentTool