import DOMPurify from "dompurify"

const ViewTextEditor = (props) => {
    function createMarkup(html) {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }
    return (   
        <span className="viewtexteditor" dangerouslySetInnerHTML={createMarkup(String(props.value).replaceAll("\n","<br/>"))}>
        </span>
    )
}

export default ViewTextEditor