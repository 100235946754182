// src/useIsStandalone.js
import { useEffect, useState } from 'react';

const useIsStandalone = () => {
  const [isStandalone, setIsStandalone] = useState(window.matchMedia('(display-mode: standalone)').matches);

  useEffect(() => {
    const handleChange = (e) => {
      setIsStandalone(e.matches);
    };

    const matchMediaQuery = window.matchMedia('(display-mode: standalone)');
    matchMediaQuery.addEventListener('change', handleChange);

    return () => {
      matchMediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return isStandalone;
};

export default useIsStandalone;
