import Button from "../Public/Button"
import InputMethod from "../Public/InputMethod"
import axios from 'axios';
import Cookies from "js-cookie"
import ServerLoading from "../Public/ServerLoading";
import { useState } from "react";

const Signin = (props) => {
    const [disabled, setDisabled] = useState(false)
    

    const signinHandler = async (e) => {
        e.preventDefault()
        setDisabled(true)

        const email = e.target.email.value
        const password = e.target.password.value

        
        if (email===""||password==="") {
            alert("Fill all required field")
            setDisabled(false)
            return
        }
        
        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN")
        formData.append("run_type","/tutor/signin")
        formData.append("email",email)
        formData.append("password",password)

        try {
            const response = await axios.post(props.api, formData);
            if (response.data.error) {
                alert(response.data.message)
            } else {
                Cookies.set("mdjdiowdtutor",response.data.tutor_id,{path: '/', expires: new Date(Date.now()+21212592000)})
                window.location.reload()
            }
            // console.log(response.data)
            setDisabled(false)
        } catch(err) {
            console.log(err)
            setDisabled(false)
        }
    }
    return (
        <div>
            <ServerLoading display={disabled} />
            <div style={{ marginTop: "0px", marginBottom: "30px"}} >
                <Button name="Sign In" styles={{marginRight:"10px"}} info />
                <Button name="Sign Up" onclick={() => {
                    props.setSignin()
                }} />
            </div>
            <form onSubmit={signinHandler}>
                <InputMethod label="Email:" name="email"/>
                <InputMethod label="Password:" name="password" password  />
                {/* <Button name="Forgotten Password?" info styles={{marginTop: "12px", float: "left"}} /> */}
                <Button name="Sign In"  styles={{marginTop: "12px", float: "right"}} form/>
                <span style={{clear: "both"}}></span>
            </form>
        </div>
    )
}
export default Signin