import React, { useState } from "react";
import "./Phone.css"; // Import CSS file for styling

const countryCodes = [
  { code: "234", name: "Nigeria (+234)" },
  { code: "1", name: "United States (+1)" },
  { code: "44", name: "United Kingdom (+44)" },
  { code: "91", name: "India (+91)" },
  // Add more country codes as needed
];

const Phone = ({ name, disabled, required }) => {
  const [countryCode, setCountryCode] = useState("234"); // Default country code
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    let value = e.target.value;
    if (/^\d*$/.test(value)) {
      if (value.startsWith("0")) {
        value = value.slice(1);
      }
      setPhoneNumber(value);
    }
  };

  return (
    <div className="phone-input-container">
      <select
        value={countryCode}
        onChange={handleCountryCodeChange}
        className="country-code-select"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>
            {isFocused ? country.name : `+${country.code}`}
          </option>
        ))}
      </select>
      <input
        type="text"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        placeholder="Phone Number"
        className="phone-number-input"
        disabled={disabled}
        required={required}
      />
      <input
        type="hidden"
        name={name}
        value={phoneNumber!=="" ? `${countryCode}${phoneNumber}` : ""}
      />
    </div>
  );
};

export default Phone;
