import React, { useState, useRef, useEffect } from 'react';
import Button from './Button';
import { Eraser, EraserFill, X, ZoomIn, ZoomOut, ArrowUp, ArrowDown, ArrowLeft, ArrowRight } from 'react-bootstrap-icons';

const Whiteboard = (props) => {
    const [color, setColor] = useState('#000000');
    const [strokeSize, setStrokeSize] = useState(2);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isErasing, setIsErasing] = useState(false);
    const [zoom, setZoom] = useState(2.8);
    const [pan, setPan] = useState({ x: 0, y: 0 });

    const canvasRef = useRef(null);
    const contextRef = useRef(null);

    const canvasContainer = useRef(null)

    const clearCanvas = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const getCoordinates = (nativeEvent) => {
        const canvas = canvasRef.current;
        const boundingRect = canvas.getBoundingClientRect();
        const offsetX = nativeEvent.touches ? nativeEvent.touches[0].clientX - boundingRect.left : nativeEvent.clientX - boundingRect.left;
        const offsetY = nativeEvent.touches ? nativeEvent.touches[0].clientY - boundingRect.top : nativeEvent.clientY - boundingRect.top;
        return {
            offsetX: (offsetX - pan.x) / zoom,
            offsetY: (offsetY - pan.y) / zoom
        };
    };

    const zoomIn = () => {
        setZoom(prevZoom => Math.min(prevZoom + 0.1, 3));
    };

    const zoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom - 0.1, 1));
    };

    const scrollHorizontal = (direction) => {
        const container = canvasContainer.current;
        container.scrollLeft += direction === 'left' ? -50 : 50;
    };

    const scrollVertical = (direction) => {
        const container = canvasContainer.current;
        container.scrollTop += direction === 'up' ? -50 : 50;
    };

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = getCoordinates(nativeEvent);
        contextRef.current.beginPath();
        contextRef.current.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    };

    const finishDrawing = () => {
        const canvas = canvasRef.current;
        setIsDrawing(false);
        const dataUrl = canvas.toDataURL('image/png');
        props.setImgURL(props.imgId, dataUrl)
    };

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) return;
        const { offsetX, offsetY } = getCoordinates(nativeEvent);
        if (isErasing) {
            contextRef.current.globalCompositeOperation = 'destination-out'; // Use 'destination-out' to simulate erasing
            contextRef.current.arc(offsetX, offsetY, strokeSize / 2, 0, Math.PI * 2);
            contextRef.current.fill();
            contextRef.current.globalCompositeOperation = 'source-over'; // Reset composite operation
        } else {
            contextRef.current.lineTo(offsetX, offsetY);
            contextRef.current.stroke();
        }
    };

    const preventDefault = (e) => {
        e.preventDefault();
    };

    const toggleEraser = () => {
        setIsErasing(!isErasing);
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            canvas.addEventListener('touchmove', preventDefault, { passive: false });
            return () => {
                canvas.removeEventListener('touchmove', preventDefault);
            };
        }
    }, []);

    useEffect(() => {
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.lineCap = 'round';
            ctx.strokeStyle = color;
            ctx.lineWidth = strokeSize;
            contextRef.current = ctx;
        }
    }, [color, strokeSize, isErasing]);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (canvas) {
            const ctx = canvas.getContext('2d');
            ctx.save();
            ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transform
            ctx.scale(zoom, zoom);
            ctx.translate(pan.x, pan.y);
            ctx.restore();
        }
    }, [zoom, pan]);

    return (
        <div className="whiteboard-container" style={{ height: "fit-content", border: "1px solid #12529f", overflow: "hidden" }}>
            <div className="whiteboard-tools" style={{ borderBottom: "1px solid #12529f", padding: "10px", textAlign: "center" }}>
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<X style={{ width: "25px", height: "25px" }} />} onclick={() => { clearCanvas(); props.removeImg(props.imgId); }} error />
                <input type="color" style={{ height: "40px", float: "left", margin: "4px 6px", }} value={color} onChange={(e) => setColor(e.target.value)} />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={isErasing ? <EraserFill style={{ color: "#12529f", width: "25px", height: "25px" }} /> : <Eraser style={{ color: "#12529f", width: "25px", height: "25px" }} />} onclick={toggleEraser} info />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<ZoomIn style={{ width: "25px", height: "25px" }} />} onclick={zoomIn} />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<ZoomOut style={{ width: "25px", height: "25px" }} />} onclick={zoomOut} />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<ArrowUp style={{ width: "25px", height: "25px" }} />} onclick={() => scrollVertical('up')} />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<ArrowDown style={{ width: "25px", height: "25px" }} />} onclick={() => scrollVertical('down')} />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<ArrowLeft style={{ width: "25px", height: "25px" }} />} onclick={() => scrollHorizontal('left')} />
                <Button styles={{ margin: "4px 6px", float: "left" }} name={<ArrowRight style={{ width: "25px", height: "25px" }} />} onclick={() => scrollHorizontal('right')} />
                <div style={{ display: "flex", clear: "both", marginTop: "60px", fontSize: "20px" }}>
                    <span style={{ marginRight: "4px" }}>-</span>
                    <input style={{ marginTop: "8px" }} type="range" min="1" max="20" value={strokeSize} onChange={(e) => setStrokeSize(parseInt(e.target.value))} />
                    <span style={{ marginLeft: "4px" }}>+</span>
                </div>
            </div>
            <div ref={canvasContainer} style={{ width: "100%", height: "368px", overflow: "hidden", position: 'relative' }}>
                <div style={{ transform: `scale(${zoom}) translate(${pan.x}px, ${pan.y}px)`, transformOrigin: '0 0' }}>
                    <canvas
                        ref={canvasRef}
                        onMouseDown={startDrawing}
                        onMouseMove={draw}
                        className="whiteboard-canvas"
                        onMouseUp={finishDrawing}
                        onTouchStart={startDrawing}
                        onTouchMove={draw}
                        onTouchEnd={finishDrawing}
                        onTouchCancel={finishDrawing}
                        width={1640}
                        height={1640}
                        style={{ clear: "both", background: "#fff" }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Whiteboard;
