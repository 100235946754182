import Button from "../../../../Public/Button"

const Classholder = (props) => {
    return (
        <>
            <Button className="pre_class_conv_holder default_bg" styles={{
                width: "100%",
                textAlign: "left",
                margin: "1px 0px",
                position: "relative",
                fontSize: "16px",
                display: "flex",
                flexWrap: "wrap",
                padding: "8px"

            }} onclick={() => props.showClassConv(props.data.class_id, props.data.class_subject, props.data.special)} name={
                <>
                    <span style={{
                        display: "block",
                        width: "20%",
                    }}>
                        <span className="primary_bg" style={{
                            fontSize: "22px",
                            display: "block",
                            borderRadius: "50%",
                            lineHeight: "2",
                            textAlign: "center",
                            maxWidth: "45px",
                            maxHeight: "45px",
                            margin: "auto"
                        }}>
                            {props.data.class_subject[0].toUpperCase()+props.data.class_subject[1].toUpperCase()}
                        </span>
                    </span>
                    <span style={{
                        width: "58%",
                        margin: "0px .50%"
                    }}>
                        <span style={{
                            fontSize: "12px",
                            display: "block"
                        }}>
                            {props.data.class_type}
                        </span>
                        <span style={{
                            fontSize: "18px"
                        }}>
                            {props.data.class_subject} Class Room
                        </span>
                    </span>
                    <span style={{
                        width: "20%", 
                        fontSize: "12px",
                        textAlign: "right"
                    }}>
                        <span>
                            {props.data.update_time}
                        </span>
                        <br/>
                        <span>
                            {/* update dot */}
                        </span>
                    </span>
                </>
            } />
        </>
    )
}

export default Classholder