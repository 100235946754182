import { ArrowLeft, Plus, SendFill, X } from "react-bootstrap-icons";
import Button from "../../../Public/Button";
import { useEffect, useState, useRef } from "react";
import CreateTools from "./subcomponents/CreateTools";
import Cookies from "js-cookie";
import TextEditors from "./TextEditors";
import ViewClassRoomMsg from "./subcomponents/ViewClassRoomMsg";

const ClassRoom = (props) => {
    const [createTools, setCreateTools] = useState(false);
    const [rm_id, setRmId] = useState(props.data.id);
    const [announ_, setAnnoun_] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [loadBtn, setLoadBtn] = useState(false)

    const containerBody = useRef(null);
    const tutor_id = Cookies.get("mdjdiowdtutor") || "";

    useEffect(() => {
        if (props.data.id !== rm_id) {
            setCreateTools(false);
            setRmId(props.data.id);
            containerBody.current?.scrollTo(0, containerBody.current?.scrollHeight);
            setAnnoun_("");
            setItemsPerPage(10);
        }
    }, [props.data.id, rm_id]);

    useEffect(() => {
        if (props.data.id === "") {
            props.setShowClassRoom(false);
        }
        // eslint-disable-next-line
    }, [props.data.id, props.setShowClassRoom]);

    useEffect(() => {
        const handleScroll = () => {
            // Check if the user has scrolled to the top
            if (containerBody.current && containerBody.current.scrollTop === 0) {
                // Call your function here
                loadMore();
            }
        };

        const container = containerBody.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, [itemsPerPage]);

    const loadMore = async () => {
        if (containerBody.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerBody.current;
            const pastScroll = scrollHeight
            setItemsPerPage(prevItemsPerPage => prevItemsPerPage + 20);
            const currentScroll = (containerBody.current.scrollHeight-pastScroll) 
            await containerBody.current.scrollTo(0, currentScroll)
            console.log(pastScroll, containerBody.current.scrollHeight, currentScroll)
        }
    }

    const MsgView = () => {
        try {
            return props.msgs
                .filter((msg) => msg.class_id.trim() === props.data.id)
                .reverse()
                .slice(0, itemsPerPage)
                .reverse()
                .map((msg, key) => (
                    <div key={key}>
                        <ViewClassRoomMsg msg={msg} />
                    </div>
                ));
        } catch (error) {
            console.log(error);
            return null; // Return null in case of error
        }
    };

    return (
        <>
            {props.data.id !== "" ?
                <div className="class_room_t">
                    <div className="class_room_header default_bg" style={{
                        padding: "8px 1px",
                    }}>
                        {props.winWidth < 699.9 ?
                            <Button name={<ArrowLeft />} info styles={{
                                border: "none",
                                boxShadow: "none",
                                fontSize: "20px",
                                fontWeight: "bold"
                            }} onclick={() => props.setShowClassRoom(false)} />
                            : ""}
                        <span style={{ marginLeft: "12px", fontWeight: "bold", fontSize: "18px", textTransform: "uppercase" }}>
                            {props.data.name} Class Room
                        </span>
                    </div>
                    <div className="class_room_body" style={{
                        overflowY: "scroll",
                        paddingBottom: "150px"
                    }} ref={containerBody}>
                        {
                            props.msgs.filter((msg) => msg.class_id.trim() === props.data.id).length>10 ?
                            <Button styles={{
                                margin: "0 auto",
                                marginTop: "10px",
                                display: loadBtn ? "none" : "block"
                            }} name={
                                <>
                                    <span style={{fontSize: "12px", fontStyle: "italic"}}>
                                        more
                                    </span>
                                </>
                            } onclick={()=> {
                                loadMore()
                                setLoadBtn(true)
                            }} />
                            :
                            ""
                        }
                        {props.msgs.filter((msg) => msg.class_id.trim() === props.data.id).length>0 ?
                            MsgView()
                            : 
                            <Button className="system_msg" name={<span>No Tutor Message Yet</span>} />
                        }
                    </div>
                    <div className="class_room_footer default_bg" style={{
                        display: "flex",
                        flexWrap: "wrap"
                    }}>
                        <CreateTools send_class_msg={props.send_class_msg} api={props.api} file={props.file} room_id={props.data.id} winWidth={props.winWidth} type={props.data.special} setCreateTools={setCreateTools} display={createTools} room_name={props.data.name} />
                        <div className="create_holder" style={{ position: "relative" }}>
                            <Button styles={{
                                left: "50%",
                                display: "block",
                                fontSize: "15px",
                                top: "50%",
                                transform: "translate(-50%,-50%)",
                                position: "absolute",
                            }} onclick={() => setCreateTools(!createTools)} name={
                                <>
                                    {!createTools ? <Plus className="class_room_footer_icons" /> : <X className="class_room_footer_icons" />}
                                </>
                            } error={createTools ? true : false} />
                        </div>
                        <div className="text_holder">
                            <textarea onChange={(e) => {
                                setAnnoun_(e.target.value)
                            }} className="txA" style={{
                                width: "100%",
                                marginTop: "3px",
                                border: "none"
                            }} value={announ_}></textarea>
                        </div>
                        <div className="send_holder">
                            <Button styles={{
                                left: "50%",
                                display: "block",
                                fontSize: "15px",
                                top: "50%",
                                transform: "translate(-50%,-50%)",
                                position: "absolute"
                            }} name={<SendFill className="class_room_footer_icons" />} onclick={() => {
                                if (announ_) {
                                    props.send_class_msg(tutor_id, "0", String(announ_), "", "", rm_id)
                                    setAnnoun_("")
                                } else {
                                    alert("Announce something!")
                                }
                            }} />
                        </div>
                    </div>
                </div>
                :
                <div>
                    Success Online Class
                </div>
            }
        </>
    )
}

export default ClassRoom;