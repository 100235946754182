import React, { useState, useEffect, useRef } from "react";
import "./ImageSlide.css"; // Import CSS file for styling


const ImageSlide = ({ file, images, slideInterval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () => setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length),
      slideInterval
    );

    return () => {
      resetTimeout();
    };
  }, [currentIndex, images.length, slideInterval]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="image-slider">
      <div
        className="slides"
        style={{ transform: `translateX(${-currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="slide" key={index}>
            <img src={file+image} alt={`Slide ${index}`} />
          </div>
        ))}
      </div>
      <div className="dots">
        {images.map((_, idx) => (
          <div
            key={idx}
            className={`dot ${currentIndex === idx ? "active" : ""}`}
            onClick={() => goToSlide(idx)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default ImageSlide;
