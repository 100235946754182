const Button = (props) => {
    // button type; Success, Error, Info/inverse primary, Primary, 

    const selected_style = () => {
        if (props.success) {
            return {
                background: "#28a745",
                color: "#fff"
            }
        }
        if (props.error) {
            return {
                background: "#dc3545",
                color: "#fff"
            }
        }
        if (props.info) {
            return {
                background: "#fff",
                color: "#12529f",
                border: "1px solid #12529f"
            }
        }
        if (props.primary) {
            return {
                background: "#12529f",
                color: "#fff"
            }
        }
        return {
            background: "#12529f",
            color: "#fff"
        }
    }

    return (
        <button onClick={props.onclick} disabled={props.disabled} className={`btn_ ${props.className} `} id={props.id} type={props.form ? "submit" : "button"} style={{...selected_style(),...props.styles}}>
            {props.name}
        </button>
    )
}
export default Button