import { Easel, Sticky } from "react-bootstrap-icons"
import Button from "../../../../Public/Button"
import { useState } from "react"
import NewTopic from "./NewTopic"
import AddLesson from "./AddLesson"
import CBT from "./CBT"
import TMA from "./TMA"

const CreateTools = (props) => {
    const [tools, showTools] = useState(false)
    const [tool, setTool] = useState(0)

    const showToolType = (tool) => {
        showTools(true)
        setTool(tool)
    }

    const hideToolType = () => {
        showTools(false)
        props.setCreateTools(false)
    }

    return (
        <>
            {  
                tools
                ?
                <>
                    {
                        tool===0
                        ?
                        <NewTopic api={props.api} room_id={props.room_id} hideToolType={hideToolType} type={props.type} winWidth={props.winWidth} send_class_msg={props.send_class_msg} room_name={props.room_name} />
                        :
                        tool===1
                        ?
                        <AddLesson api={props.api} room_id={props.room_id} hideToolType={hideToolType} type={props.type} winWidth={props.winWidth} send_class_msg={props.send_class_msg} room_name={props.room_name} />
                        :
                        tool===2
                        ?
                        <CBT api={props.api} room_id={props.room_id} hideToolType={hideToolType} type={props.type} winWidth={props.winWidth} send_class_msg={props.send_class_msg} room_name={props.room_name} />
                        :
                        <TMA api={props.api} room_id={props.room_id} hideToolType={hideToolType} type={props.type} winWidth={props.winWidth} send_class_msg={props.send_class_msg} room_name={props.room_name} />
                    }
                </>
                :
                ""
            }
            <div className="class_create_tools default_bg" style={{display: props.display? "block": "none", fontSize: "12px"}}>
                <p style={{margin: "1px 0px", fontWeight: "bold", fontSize: "14px", textAlign: "center"}}>Teach</p>
                <Button styles={{width: "49%", textAlign: "center", margin: "3px .5%"}} onclick={() => showToolType(0)} name={
                    <>
                        <Easel style={{
                            display: "block",
                            fontSize: "35px",
                            margin: "4px auto",
                            fontWeight: "bolder"
                        }}/>
                        New Topic
                    </>
                } />
                <Button styles={{width: "49%", textAlign: "center", margin: "3px .5%"}}onclick={() => showToolType(1)}  name={
                    <>
                        <Easel style={{
                            display: "block",
                            fontSize: "35px",
                            margin: "4px auto",
                            fontWeight: "bolder"
                        }}/>
                        Add Lesson                 
                    </>
                } />
                <p style={{margin: "1px 0px", fontWeight: "bold", fontSize: "14px", textAlign: "center"}}>Assignment</p>
                <Button styles={{width: "49%", textAlign: "center", margin: "3px .5%"}} onclick={() => showToolType(2)} name={
                    <>
                        <Sticky style={{
                            display: "block",
                            fontSize: "35px",
                            margin: "4px auto",
                            fontWeight: "bolder"
                        }} />
                        CBT
                    </>
                } />
                <Button styles={{width: "49%", textAlign: "center", margin: "3px .5%"}}onclick={() => showToolType(3)}  name={
                    <>
                        <Sticky style={{
                            display: "block",
                            fontSize: "35px",
                            margin: "4px auto",
                            fontWeight: "bolder"
                        }} />
                        TMA
                    </>
                } />
            </div>
        </>
    )
}

export default CreateTools