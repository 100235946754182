import { useEffect, useState } from "react";
import Cookies from "js-cookie"
import ServerLoading from "../../Public/ServerLoading";
import ClassConversation from "./components/ClassConversation";
import ClassRoom from "./components/ClassRoom";
import WhatsAppIcon from "../../../../assets/img/whatsapp.png"
import Button from "../../Public/Button";


const Class = (props) => {
    const [disabled, setDisabled] = useState(false)
    const [tutor, setTutor] = useState([])
    const [userClass, setClasses] = useState(false)
    const [showClassRoom, setShowClassRoom] = useState(true)
    const [convData, setConvData] = useState({
        id: "",
        name: "",
        special: "",
        draft: ""
    })
    const [msgs, setMsgs] = useState(false)
    const [_404] = useState(true)

    const tutor_id = Cookies.get("mdjdiowdtutor")||""

    useEffect(() => {
        if (tutor_id) {
            const newMessages = JSON.parse(Cookies.get("tutorDetails"))
            setTutor(newMessages)
        }
    }, [tutor_id])

    useEffect(() => {
        if (tutor_id) {
            const tutor_classes = () => {
                const storedData = sessionStorage.getItem('tutors_classes');
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    const jsonArr = parsedData
                    const sortArrDs = jsonArr.sort((a, b) => b.update_time - a.update_time)
                    setClasses(sortArrDs);
                }
            }
            const class_msg = () => {
                const storedData = sessionStorage.getItem('class_msg');
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    const jsonArr = parsedData
                    const sortArrAs = jsonArr.sort((a, b) => a.date - b.date)
                    setMsgs(sortArrAs);
                }
            }
    
            tutor_classes()
            class_msg()

            const intervalId = setInterval(()=> {
                tutor_classes()
                class_msg()
            }, 300);
    
            return () => {
              clearInterval(intervalId);
            };
        }
    },[tutor_id])

    const tutor_WA_grp = (subject) => {
        const generalClassRoom = ['English', 'Mathematics'];
        const scienceClassRoom = ['Biology', 'Physics', 'Chemistry'];
        const socialArtClassRoom = ['Government', 'Literature', 'CRS', 'Economics'];

        if (generalClassRoom.includes(subject)) {
            return (
                <a href="https://chat.whatsapp.com/BgJVp4X3iQ28WHLwAOjRFy" style={{
                    display: "block",
                    background: "#25D366",
                    color: "#fff",
                    paddingTop:"10px",
                    paddingBottom: "10px",
                    paddingLeft: "12px",
                    borderRadius: "8px",
                    fontSize: "13px",
                    width: "fit-content",
                    textDecoration: "none", 
                    textAlign: "center",
                    margin: "5px auto",
                    position: "relative",
                    paddingRight: "50px"
                }}>
                    Enter {subject} WA Classroom
                    <span style={{
                        width: "35px",
                        height: "36px",
                        overflow: "hidden",
                        display: "block",
                        position: "absolute",
                        right: "7px",
                        top: "50%",
                        borderRadius: "35px",
                        transform: "translate(0%, -50%)"
                    }}>
                        <img src={WhatsAppIcon} style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }} alt="" />
                    </span>
                </a> 
            )
        } else if (scienceClassRoom.includes(subject)) {
            return (
                <a href={"https://chat.whatsapp.com/KvU4Wvfm5IaDQB8afXfuLf"} style={{
                    display: "block",
                    background: "#25D366",
                    color: "#fff",
                    paddingTop:"10px",
                    paddingBottom: "10px",
                    paddingLeft: "12px",
                    borderRadius: "8px",
                    fontSize: "13px",
                    width: "fit-content",
                    textDecoration: "none", 
                    textAlign: "center",
                    margin: "5px auto",
                    position: "relative",
                    paddingRight: "50px"
                }}>
                    Enter {subject} WA Classroom
                    <span style={{
                    width: "35px",
                    height: "36px",
                    overflow: "hidden",
                    display: "block",
                    position: "absolute",
                    right: "7px",
                    top: "50%",
                    borderRadius: "35px",
                    transform: "translate(0%, -50%)"
                    }}>
                        <img src={WhatsAppIcon} style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }} alt="" />
                    </span>
                </a>
            )
        } else if (socialArtClassRoom.includes(subject)) {
            return (
                <a href={"https://chat.whatsapp.com/KTVtYPKdm10DBfiNkmDs7L"} style={{
                    display: "block",
                    background: "#25D366",
                    color: "#fff",
                    paddingTop:"10px",
                    paddingBottom: "10px",
                    paddingLeft: "12px",
                    borderRadius: "8px",
                    fontSize: "13px",
                    width: "fit-content",
                    textDecoration: "none", 
                    textAlign: "center",
                    margin: "5px auto",
                    position: "relative",
                    paddingRight: "50px"
                }}>
                    Enter {subject} WA Classroom
                    <span style={{
                    width: "35px",
                    height: "36px",
                    overflow: "hidden",
                    display: "block",
                    position: "absolute",
                    right: "7px",
                    top: "50%",
                    borderRadius: "35px",
                    transform: "translate(0%, -50%)"
                    }}>
                        <img src={WhatsAppIcon} style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }} alt="" />
                    </span>
                </a>
            )
        } else {
            return (
                <Button name="Couldn't determine department. Please refresh" styles={{
                    display: "block",
                    fontSize: "13px",
                    margin: "5px auto",
                    borderRadius: "8px",
                    cursor: "not-allowed"
                }} error />
            )
        }
    }

    const showClassConv = (id, name, special) => {
        setShowClassRoom(true)
        setConvData({
            id: id,
            name: name,
            special: special,
            draft: sessionStorage.getItem("draft_"+id)
        })
    }

    return (
        <div>
            {
                !_404
                ?
                <>
                    <ServerLoading display={disabled} />
                    <div className="class_">
                        <ClassConversation msgs={msgs} classes={userClass} file={props.file} winWidth={props.winWidth} api={props.api} showClassConv={showClassConv} />
                        <div className="bg_img" style={{
                            display: !(props.winWidth<=699.9)?"block":props.winWidth<=699.9&&showClassRoom?"block":"none"
                        }}>
                            <ClassRoom msgs={msgs} send_class_msg={props.send_class_msg} api={props.api} data={convData} file={props.file} winWidth={props.winWidth} setShowClassRoom={setShowClassRoom} />
                        </div>
                    </div>
                </>
                :
                <div style={{
                    textAlign: "center",
                    background: "#fff",
                    height: "100%"
                }}>
                    <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        {
                            tutor.subject?.split(",").map((subject, key) => {
                                return (
                                    <span key={key}>
                                        {tutor_WA_grp(subject)}
                                    </span>
                                )
                            })
                        }
                    </div>
                </div>
            }
            
        </div>
    )
}

export default Class;