const ServerLoading = (props) => {
    return ( 
        <div className="loader_holder" style={{
            display: props.display ? "block": "none"
        }}>
            <div className="loader" ></div>
            <div className="loadingTextHolder">
                {
                    props.progress
                    ?
                    <>
                        {!props.perc ? 0 : props.perc}% uploaded
                    </>
                    :
                    <>
                        <div className="loadingText">Please Wait...</div>
                        <div className="loadingText2">Getting data from Server</div>
                        <div className="loadingText3">This doesn't normally take long...</div>
                        <div className="loadingText4">I think your connection is very slow</div>
                        <div className="loadingText5">You should probably refresh... </div>
                    </>
                }
            </div>
        </div>
    );
}
 
export default ServerLoading;