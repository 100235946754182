// LinkComponent.js
import React, { useState, useEffect } from 'react';
import FetchSiteDetails from './FetchSiteDetails';

const LinkComponent = ({ link }) => {
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const fetchDetails = async () => {
      const siteDetails = await FetchSiteDetails(link);
      setDetails(siteDetails);
    };

    fetchDetails();
  }, [link]);

  return (
    <div>
      {details ? (
        <div>
          <img src={details.logo} alt={details.name} style={{ width: '50px', height: '50px', margin: "0px" }} />
          <p style={{margin: "0px"}}>{details.title}</p>
          <p style={{margin: "0px"}}>{details.name}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default LinkComponent;
