import ViewTextEditor from "../ViewTextEditor"

const ViewTopic = (props) => {
    return (
        <div>
            <div style={{fontWeight: "bolder", fontSize: "20px"}}>
                <ViewTextEditor value={props.msg.content} />
            </div>
            <div style={{margin: "12px 0px"}}>
                <span style={{fontWeight: "bold"}}>Lesson Outline:</span> <ViewTextEditor value={props.msg.lesson_outlines} />
            </div>
            <div style={{margin: "12px 0px"}}>
                <span style={{fontWeight: "bold"}}>Teaching Objectives:</span> <ViewTextEditor value={props.msg.teaching_objectives} />
            </div>
        </div>
    )
}

export default ViewTopic