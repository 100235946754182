import { Routes, Route } from 'react-router-dom';
import AppStyle from './AppStyle';
import { useCallback, useEffect, useRef, useState } from 'react';
import ServerLoading from '../pages/components/Public/ServerLoading';
import axios from 'axios';
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import Home from '../pages/Home';
import Auth from '../pages/Auth';
import Logout from '../pages/components/Auth/Logout';
import VerifyEmail from './VerifyEmail';
import VerifyTutor from './VerifyTutor';
import TutorPending from './TutorPending';

const Appview = (props) => {
    const [disabled, setDisabled] = useState(true);
    const [tutor, setTutor] = useState("");
    const [emailVerified, setEmailVerified] = useState(true);
    const [email, setEmail] = useState("");
    const [tutorVerified, setTutorVerified] = useState(true);
    const [tutorPending, setTutorPending] = useState(false);

    const eventSourceRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const prevDataRef = useRef("...");

    const navigate = useNavigate();
    const tutor_id = Cookies.get("mdjdiowdtutor") || "";

    const tutorClassRoom = useCallback(async () => {
        let formData = new FormData();
        formData.append("tutor_id", tutor_id);

        try {
            const response = await axios.post(props.api + "tutor/tutors_classes", formData);
            if (response.data.error) {
                alert(response.data.message);
            } else {
                const jsonArr = response.data;
                const sortArrAs = jsonArr.sort((a, b) => b.update_time - a.update_time);
                sessionStorage.setItem('tutors_classes', JSON.stringify(sortArrAs));
            }
        } catch (err) {
            alert('Error fetching tutor classes');
        } finally {
            setDisabled(false);
        }
    }, [props.api, tutor_id]);

    const classRoomMsg = useCallback(async () => {
        let formData = new FormData();
        formData.append("tutor_id", tutor_id);

        try {
            const response = await axios.post(props.api + "tutor/class_msg", formData);
            if (response.data.error) {
                alert(response.data.message);
            } else {
                sessionStorage.setItem('class_msg', JSON.stringify(response.data));
            }
        } catch (err) {
            alert('Error fetching class messages');
        } finally {
            setDisabled(false);
        }
    }, [props.api, tutor_id]);

    const removeTutorFromConv = () => {
        console.log("removing tutor from class room")
    }

    const addTutorToConv = () => {
        console.log("adding tutor to class room")
    }

    const updateTutorData = (data) => {
        try {
            const newMessages = JSON.parse(data);
            if (JSON.stringify(tutor) !== JSON.stringify(newMessages)) {
                setTutor(newMessages);

                Cookies.set("tutorDetails", JSON.stringify(newMessages),{path: '/', expires: new Date(Date.now()+21212592000)})

                if (emailVerified && newMessages.email_verify === "0") {
                    setEmail(newMessages.email);
                    setEmailVerified(false);
                }

                if (newMessages.tutor_verified === "1") {
                    setTutorVerified(true);
                    setTutorPending(false);
                    // addTutorToConv();
                } else if (newMessages.tutor_verified === "2" || newMessages.tutor_verified === "3") {
                    setTutorVerified(true);
                    setTutorPending(true);
                    // removeTutorFromConv();
                } else {
                    setTutorVerified(false);
                    setTutorPending(false);
                    // removeTutorFromConv();
                }
            }
        } catch (error) {
            console.error("Error parsing SSE data:", error);
        } finally {
            setDisabled(false);
        }
    };

    const initializeEventSource = () => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }

        const eventSource = new EventSource("https://server.welearn.com.ng/sse/tutor_details.php?tutor_id=" + tutor_id);
        eventSourceRef.current = eventSource;

        eventSource.onmessage = function (event) {
            if (event.data === "false") {
                alert("Tutor doesn't exist!");
                Cookies.remove("mdjdiowdtutor", "", -1);
                Cookies.remove("tutorDetails", "", -1);
                navigate("/");
                window.location.reload();
                return;
            }
            if (JSON.stringify(prevDataRef.current) !== JSON.stringify(event.data)) {
                prevDataRef.current = event.data;
                updateTutorData(event.data);
                
            }
        };

        eventSource.onerror = function (error) {
            console.error("EventSource error:", error);
            eventSource.close();
            if (!reconnectTimeoutRef.current) {
                reconnectTimeoutRef.current = setTimeout(() => {
                    initializeEventSource();
                    reconnectTimeoutRef.current = null;
                }, 5000); // Reconnect after 5 seconds
            }
        };
    };

    useEffect(() => {
        if (tutor_id) {
            initializeEventSource();

            return () => {
                if (eventSourceRef.current) {
                    eventSourceRef.current.close();
                }
                if (reconnectTimeoutRef.current) {
                    clearTimeout(reconnectTimeoutRef.current);
                }
            };
        } else {
            setDisabled(false);
        }
    }, [tutor_id]);

    function get_time() {
        return Date.now();
    }

    const generateRandomNumber = () => {
        return Math.floor(Math.random() * 90000) + 10000;
    };

    const send_class_msg = (tutor_id, topic, message, teaching_objectives, lesson_outlines, class_id) => {
        // Implementation for sending class message
    };

    const checkLogin = () => {
        if (tutor_id) {
            return (
                <>
                    <Route path="/" element={<Home send_class_msg={send_class_msg} api={props.api} file={props.file} winWidth={props.winWidth} />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="*" element={<div>Page Unavailable</div>} />
                </>
            );
        } else {
            return (
                <>
                    <Route path="*" element={<Auth api={props.api} file={props.file} winWidth={props.winWidth} />} />
                </>
            );
        }
    };

    return (
        <>
            <ServerLoading display={disabled} />
            <AppStyle api={props.api} theme={true} file={props.file} />
            <Routes>
                {emailVerified ? (
                    tutorVerified ? (
                        !tutorPending ? (
                            disabled ? (
                                <Route path="*" element={<p></p>} />
                            ) : (
                                checkLogin()
                            )
                        ) : (
                            <>
                                <Route path="*" element={<TutorPending api={props.api} file={props.file} winWidth={props.winWidth} setTutorVerified={setTutorVerified} setTutorPending={setTutorPending} tutor={tutor} />} />
                                <Route path="/logout" element={<Logout />} />
                            </>
                        )
                    ) : (
                        <>
                            <Route path="*" element={<VerifyTutor api={props.api} file={props.file} winWidth={props.winWidth} setTutorVerified={setTutorVerified} setTutorPending={setTutorPending} />} />
                            <Route path="/logout" element={<Logout />} />
                        </>
                    )
                ) : (
                    <>
                        <Route path="*" element={<VerifyEmail email={email} api={props.api} file={props.file} winWidth={props.winWidth} setEmailVerified={setEmailVerified} />} />
                        <Route path="/logout" element={<Logout />} />
                    </>
                )}
            </Routes>
        </>
    );
};

export default Appview;
