// FetchSiteDetails.js
const FetchSiteDetails = async (url) => {
    const apiKey = '15cda7d11b2868f17a8e4ec39cd02922'; // Replace with your LinkPreview API key
    const apiUrl = `https://api.linkpreview.net/?key=${apiKey}&q=${encodeURIComponent(url)}`;
  
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();
  
      if (response.ok) {
        return {
          title: data.title,
          logo: data.image,
          name: data.site_name
        };
      } else {
        console.error('Error fetching site details:', data);
        return null;
      }
    } catch (error) {
      console.error('Error fetching site details:', error);
      return null;
    }
  };
  
  export default FetchSiteDetails;
  