import { useEffect, useState } from "react"
import { Eye, EyeSlash } from "react-bootstrap-icons"
import Phone from "./Phone"

const InputMethod = (props) => {
    const [pwd, setPwd] = useState(true)
    const [checklist, setChecklist] = useState(false)
    const [pwdExt, setPwdExt] = useState(false)
    const [date, setdate] = useState(false)
    const [radio, setRadio] = useState(false)
    const [type, setType] = useState("text")

    useEffect(() => {
        if (props.password) {
            setPwdExt(true)
            setType("password")
        }
        if (props.checklist) {
            setChecklist(true)
            setType("checkbox")
        }
        if (props.date) {
            setdate(true)
            setType("date")
        }
        if (props.radio) {
            setRadio(true)
            setType("radio")
        }
    },[props.password, props.checklist, props.date, props.radio])

    const changeType = () => {
        setPwd(!pwd)
    }

    return (
        <>
                
            {
                props.phone?
                <div className="form-group" style={{marginTop: "12px"}}>
                    <label title={props.title} style={{fontWeight: "bold"}}>
                        {props.label}
                    </label>
                    <Phone  
                        disabled={props.disabled}
                        name={props.name} 
                        required={props.required}
                        style={{
                            
                        }}
                    />
                </div>:
                pwdExt
                ?
                <div className="form-group" style={{marginTop: "12px"}}>
                    <label title={props.title} style={{fontWeight: "bold"}}>
                        {props.label}
                    </label>
                    <input 
                        type={pwd ? "password" : "text"} 
                        className="form-control pswd" 
                        disabled={props.disabled}
                        name={props.name} 
                        required={props.required}
                        style={{
                            
                        }}
                    />
                    <button className="toggle_btn" onClick={changeType} type="button">
                        {pwd ? <Eye /> : <EyeSlash />}
                    </button>
                </div>
                :
                checklist
                ?
                <div style={{
                    padding: "5px 8px",
                    background: "inherit",
                    textAlign: "left",
                    border: "1px solid #12529f",
                    margin: "4px 0px",
                    position: "relative",
                }}>
                    <input 
                        type={type}  
                        name={props.name} 
                        value={props.value}
                        id={"_"+String(props.name).replaceAll(" ","").replaceAll("\n","")+String(props.value).replaceAll(" ","").replaceAll("\n","")}
                        disabled={props.disabled}
                        defaultChecked={props.checked}
                        style={{position: "absolute", top: "10px"}}
                        required={props.required}
                    />
                    <label 
                        htmlFor={"_"+String(props.name).replaceAll(" ","").replaceAll("\n","")+String(props.value).replaceAll(" ","").replaceAll("\n","")}
                        title={props.title}
                        style={{
                            marginLeft: "23px",
                            cursor: "pointer"
                        }}>
                        {props.label}
                    </label>
                </div>
                :
                date
                ?
                <div className="form-group" style={{marginTop: "12px"}}>
                    <label title={props.title} style={{fontWeight: "bold"}}>
                        {props.label}
                    </label>
                    <input 
                        type={type} 
                        className="form-control" 
                        name={props.name} 
                        disabled={props.disabled}
                        required={props.required}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                        inputid={props.inputid}
                        style={{
                            
                        }}
                    />
                </div>
                :
                radio
                ?
                <div style={{
                    padding: "5px 8px",
                    background: "inherit",
                    textAlign: "left",
                    border: "1px solid #12529f",
                    margin: "4px 0px",
                    position: "relative",
                }}>
                    <input 
                        type={type}  
                        name={props.name} 
                        value={props.value}
                        id={"_"+String(props.name).replaceAll(" ","").replaceAll("\n","")+String(props.value).replaceAll(" ","").replaceAll("\n","")}
                        disabled={props.disabled}
                        defaultChecked={props.checked}
                        style={{position: "absolute", top: "10px"}}
                        onChange={props.onChange}
                    />
                    <label 
                        htmlFor={"_"+String(props.name).replaceAll(" ","").replaceAll("\n","")+String(props.value).replaceAll(" ","").replaceAll("\n","")}
                        title={props.title}
                        style={{
                            marginLeft: "23px",
                            cursor: "pointer"
                        }}>
                        {props.label}
                    </label>
                </div>
                :
                <div className="form-group" style={{margin: "8px 0px"}}>
                    <label title={props.title} style={{fontWeight: "bold", display: !props.label?"none":"block"}}>
                        {props.label}
                    </label>
                    <input 
                        type={type} 
                        className="form-control" 
                        name={props.name} 
                        disabled={props.disabled}
                        required={props.required}
                        defaultValue={props.defaultValue}
                        onChange={props.onChange}
                        inputid={props.inputid}
                        placeholder={props.placeholder}
                        style={{
                            
                        }}
                    />
                </div>
            }
        </>
    )
}
export default InputMethod