import { useRef, useState } from "react"
import Button from "../Public/Button"
import InputMethod from "../Public/InputMethod"
import axios from 'axios';
import Cookies from "js-cookie"
import ServerLoading from "../Public/ServerLoading";

const Signup = (props) => {
    const [step, setStep] = useState(1)
    const [classType, setClassType] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [uploadProgress, setUploadPercentage] = useState("")
    const formContainer = useRef(null)

    const tutor_id = Cookies.get("mdjdiowdtutor")===undefined ? "" : Cookies.get("mdjdiowdtutor")
    const submitHandler = async (e) => {
        e.preventDefault()
        setDisabled(true)

        const firstname = String(e.target.firstname.value).trim()
        const middlename = String(e.target.middlename.value).trim()
        const surname = String(e.target.surname.value).trim()
        const whatsapp = String(e.target.whatsapp.value).trim()
        const phone = String(e.target.phone.value).trim()
        const email = String(e.target.email.value).trim()
        const dob = String(e.target.dob.value).trim()  
        const gender = e.target.gender.value  
        const password = e.target.password.value
        const repassword = e.target.repassword.value

        const guarantor_name_1 = e.target.guarantor_name_1.value
        const guarantor_phone_1 = e.target.guarantor_phone_1.value
        const guarantor_address_1 = e.target.guarantor_address_1.value
        const guarantor_occupation_1 = e.target.guarantor_occupation_1.value
        const guarantor_name_2 = e.target.guarantor_name_2.value
        const guarantor_phone_2 = e.target.guarantor_phone_2.value
        const guarantor_address_2 = e.target.guarantor_address_2.value
        const guarantor_occupation_2 = e.target.guarantor_occupation_2.value  
        const class_type = e.target.class_type.value

        let class_session = ""
        if (class_type==="SSCE/JAMB") {
            class_session = e.target.s_jamb_time.value
        } else {
            class_session = e.target.s_pume_time.value
        }
        
        const teaching_exp = e.target.teaching_exp.value
        const qualifications_ = e.target.qualifications_.value
        const area_of_study = e.target.area_of_study.value
        let subject = ""
        let x = 0
        for (let i = 0; i < e.target.preferred_subject.length; i++) {
            const subjects = e.target.preferred_subject[i]
            if (subjects.checked) {
                subject += ","+subjects.value
                x+=1
            }
        }

        subject = subject.replace(",","")
        if (x<1) {
            alert("select preferred subject")
            setDisabled(false)
            return
        }

        if (x>2) {
            alert("You can't select more than 2 subjects")
            setDisabled(false)
            return
        }

        try {
            const filename = e.target.passport.files[0].name
            if (filename==="") {
                alert("No file found")
                setDisabled(false)
                return
            }
            if (e.target.passport.files[0].size>5000000) {
                alert("passport size should not be more than 5MB")
                setDisabled(false)
                return
            }
        } catch (error) {
            alert("Select a Photo")
            setDisabled(false)
            return false
        }

        
        const acct_number = e.target.acct_number.value       
        const acct_name = e.target.acct_name.value   
        const bank_name = e.target.bank_name.value  


        if (firstname===""||surname===""||whatsapp===""||email===""||dob===""||password===""||repassword===""||acct_number===""||acct_name===""||bank_name===""||class_session===""||teaching_exp===""||qualifications_===""||area_of_study===""||guarantor_occupation_2===""||guarantor_phone_2===""||guarantor_name_2===""||guarantor_occupation_1===""||guarantor_phone_1===""||guarantor_address_1===""||guarantor_name_1===""||guarantor_address_2===""||gender===""||subject==="") {
            alert("Fill all required field (*)")
            setDisabled(false)
            return
        }


        let formData = new FormData()
        formData.append("authorization","2dkn3KN38JJ*d!den_-dkDN") 
        formData.append("run_type","/tutor/signup")
        formData.append("tutor_id",tutor_id)

        formData.append("firstname",firstname)
        formData.append("middlename",middlename)
        formData.append("surname",surname)
        formData.append("whatsapp",whatsapp)
        formData.append("phone",phone)
        formData.append("email",email)
        formData.append("dob",dob)
        formData.append("gender",gender)
        formData.append("password",password)
        formData.append("repassword",repassword)
        
        formData.append("guarantor_name_1",guarantor_name_1)
        formData.append("guarantor_phone_1",guarantor_phone_1)
        formData.append("guarantor_address_1",guarantor_address_1)
        formData.append("guarantor_occupation_1",guarantor_occupation_1)
        formData.append("guarantor_name_2",guarantor_name_2)
        formData.append("guarantor_phone_2",guarantor_phone_2)
        formData.append("guarantor_address_2",guarantor_address_2)
        formData.append("guarantor_occupation_2",guarantor_occupation_2)
        formData.append("class_type",class_type)
        formData.append("class_session",class_session)
        
        formData.append("teaching_exp",teaching_exp)
        formData.append("qualifications_",qualifications_)
        formData.append("area_of_study",area_of_study)
        formData.append("subject",subject)
        formData.append("passport",e.target.passport.files[0])
        formData.append("acct_number",acct_number)
        formData.append("acct_name",acct_name)
        formData.append("bank_name",bank_name)


        const config = {
            onUploadProgress: function (progressEvent) {
                const { loaded, total } = progressEvent
                setUploadPercentage(0)

                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    setUploadPercentage(percent);
                } else {
                    setUploadPercentage(100);
                }
            }
        }


        try {
            const response = await axios.post(props.api, formData, config);
            if (!response.data.success) {
                alert(response.data.message)
            } else {
                Cookies.set("mdjdiowdtutor",response.data.tutor_id,{path: '/', expires: new Date(Date.now()+21212592000)})
                window.location.reload()
            }
            // console.log(response.data)
            setDisabled(false)
        } catch(err) {
            console.log(err)
            setDisabled(false)
        }

    }

    const toTop = () => {
        if (formContainer.current) {
            formContainer.current.scrollIntoView({ behavior: 'smooth' })
        } 
    }

    return (
        <div>
            <ServerLoading display={disabled} progress perc={uploadProgress} />
            <div style={{ marginTop: "0px", marginBottom: "30px"}} >
                <Button name="Sign Up" styles={{marginRight:"10px"}} info />
                <Button name="Sign In" onclick={() => {
                    props.setSignin()
                }} />
            </div>
            <form style={{position: "relative", paddingTop: "40px"}} method="post" encType="multipart/form-data" onSubmit={submitHandler}>
                <span style={{padding: "2px 12px", background: "#12529f", color: "#fff", position: "absolute", right:"12px", top: "12px", borderRadius: "20px"}} ref={formContainer} id="formContainer">
                    {step}/3
                </span>
                <div style={{display:step===1?"block":"none"}}>
                    <InputMethod label="First Name: *" name="firstname" />
                    <InputMethod label="Middle Name:" name="middlename" />
                    <InputMethod label="Surname: *" name="surname" />
                    <InputMethod label="WhatsApp Number: *" name="whatsapp" phone />
                    <InputMethod label="Other Number:" name="phone" phone />
                    <InputMethod label="Email: *" name="email" />
                    <InputMethod label="Date of Birth: *" name="dob" date/>
                    <b style={{display: "block",marginTop: "10px"}}>
                        Gender: 
                    </b>
                    <div className="sm_ele_grid">
                        <InputMethod name="gender" value="male" label="Male" radio checked/>
                        <InputMethod name="gender" value="female" label="Female" radio />
                    </div>
                    <InputMethod label="Password: *" password name="password" />
                    <InputMethod label="Repeat Password: *" password name="repassword" />
                </div>
                <div style={{display:step===2?"block":"none"}}>
                    <div style={{border: "1px solid #12529f", padding: "4px 6px", marginTop: "10px"}}>
                        <b>
                            GUARANTORS:
                        </b>
                        <span style={{display: "block", fontWeight: "bold", marginTop: "10px"}}>1st Guarantor</span>
                        <div style={{marginTop: "-12px"}}>
                            <InputMethod label="Full Name: *" name="guarantor_name_1"/>
                            <InputMethod label="Phone Number: *" name="guarantor_phone_1" phone/>
                            <InputMethod label="Address: *" name="guarantor_address_1"/>
                            <b>Relationship: *</b>
                            <select name="guarantor_occupation_1" className="form-select" style={{margin: "8px 0px"}}>
                                <option>
                                    Family Relative
                                </option>
                                <option>
                                    Clergy
                                </option>
                                <option>
                                    My Lecturer/Teacher
                                </option>
                                <option>
                                    Former Employer
                                </option>
                                <option>
                                    My Community leader
                                </option>
                            </select>
                        </div>
                        <hr/>
                        <span style={{display: "block", fontWeight: "bold", marginTop: "10px"}}>2nd Guarantor</span>
                        <div style={{marginTop: "-12px"}}>
                            <InputMethod label="Full Name: *" name="guarantor_name_2"/>
                            <InputMethod label="Phone Number: *" name="guarantor_phone_2" phone/>
                            <InputMethod label="Address: *" name="guarantor_address_2"/>
                            <b>Relationship: *</b>
                            <select name="guarantor_occupation_2" className="form-select" style={{margin: "8px 0px"}}>
                                <option>
                                    Family Relative
                                </option>
                                <option>
                                    Clergy
                                </option>
                                <option>
                                    My Lecturer/Teacher
                                </option>
                                <option>
                                    Former Employer
                                </option>
                                <option>
                                    My Community leader
                                </option>
                            </select>
                        </div>
                    </div>
                    <b>
                        Program to Teach: 
                    </b>
                    <div className="sm_ele_grid">
                        <InputMethod name="class_type" value="SSCE/JAMB" label="SSCE/JAMB" radio checked onChange={() => {setClassType(true)}} />
                        <InputMethod name="class_type" value="UNIBEN Post UTME" label="UNIBEN Post UTME" radio onChange={() => {setClassType(false)}} />
                        <InputMethod name="class_type" label={<span>UNIBEN 100L <i>(coming soon)</i></span>} disabled radio />
                    </div>
                    <b>
                        Session to Teach:
                    </b>
                    <div className="sm_ele_grid" style={{display: classType?"grid":"none"}}>
                        <InputMethod name="s_jamb_time" value="Morning Session" label="Morning Session (8am - 11am)" radio checked />
                        <InputMethod name="s_jamb_time" value="Afternoon Session" label="Afternoon Session (12pm-3pm)" radio />
                        <InputMethod name="s_jamb_time" value="Evening Session" label="Evening Session (4pm-7pm)" radio />
                        <InputMethod name="s_jamb_time" value="Late Evening Session" label="Late Evening Session (8pm-11pm)" radio />
                        <InputMethod name="s_jamb_time" value="Mid Night Session" label="Mid Night Session (12am-3am)" radio />        
                    </div>
                    <div className="sm_ele_grid" style={{display: classType?"none":"grid"}}> 
                        <InputMethod name="s_pume_time" value="Morning Session" label="Morning Session (8am - 11am)" radio checked />
                        <InputMethod name="s_pume_time" value="Evening Session" label="Evening Session (8pm-11pm)" radio />            
                    </div>
                </div>
                <div style={{display:step===3?"block":"none"}}>
                    <b>
                        Teaching Experience:
                    </b>
                    <div className="sm_ele_grid" style={{display: classType?"grid":"none"}}>
                        <InputMethod name="teaching_exp" value="0-2years" label="0 - 2 years" radio checked />
                        <InputMethod name="teaching_exp" value="2-5years" label="2 - 5 years" radio />
                        <InputMethod name="teaching_exp" value="5-10years" label="5 - 10 years" radio />
                        <InputMethod name="teaching_exp" value="10years and above" label="10 years and above" radio />     
                    </div>
                    <b>
                        Qualification(s)
                    </b>
                    <div className="sm_ele_grid">
                        <InputMethod name="qualifications_" value="HND/NCE" label="HND/NCE" radio />
                        <InputMethod name="qualifications_" value="BSc" label="BSc" radio />
                        <InputMethod name="qualifications_" value="MSc" label="MSc" radio  />
                        <InputMethod name="qualifications_" value="PhD" label="PhD" radio  />
                        <InputMethod name="qualifications_" value="Undergraduate" label="Undergraduate" radio  />
                    </div>
                    <InputMethod label="Area of Study(e.g. BSc(Mathematics)): *" name="area_of_study"/>
                    <b>
                        Preferred Subject(s) Taught <i>(You can only select a maximum of 2)</i>: 
                    </b>
                    <div className="sm_ele_grid">
                        <InputMethod name="preferred_subject" value="English" label="English" checklist />
                        <InputMethod name="preferred_subject" value="Mathematics" label="Mathematics" checklist />
                        <InputMethod name="preferred_subject" value="Biology" label="Biology" checklist  />
                        <InputMethod name="preferred_subject" value="Physics" label="Physics" checklist  />
                        <InputMethod name="preferred_subject" value="Chemistry" label="Chemistry" checklist  />
                        <InputMethod name="preferred_subject" value="Government" label="Government" checklist  />
                        <InputMethod name="preferred_subject" value="Literature" label="Literature" checklist  />
                        <InputMethod name="preferred_subject" value="CRS" label="CRS" checklist  /> 
                        <InputMethod name="preferred_subject" value="Economics" label="Economics" checklist  /> 
                    </div>

                    <div className="form-group">
                        <label htmlFor="passport" className="form-label" style={{fontWeight: "bold"}}>Passport: * </label>
                        <input type="file" id="passport" name="passport" className="form-control" accept="image/*" />
                    </div>
                    <div style={{border: "1px solid #12529f", padding: "4px 6px", marginTop: "10px"}}>
                        <b>ACCOUNT DETAILS</b>
                        <div style={{marginTop: "-12px"}}>
                            <InputMethod label="Account Number: *" name="acct_number"/>
                            <InputMethod label="Account Name: *" name="acct_name"/>
                            <InputMethod label="Bank Name: *" name="bank_name"/>
                        </div>
                    </div>
                </div>

                <div style={{position: "relative", marginTop: "12px"}}>
                    <Button name="Prev" styles={{display: step<= 1 ? "none" : "block", position: "absolute", left: "0px", top: "0px"}} onclick={() => {
                        setStep(step-1)
                        toTop()                 
                    }} />
                    
                    <Button name="Next" onclick={() => {
                        setStep(step+1)                        
                        toTop()
                    }} styles={{position: "absolute", right: "0px", top: "0px", display: step<3?"inline-block":"none"}}/>
                    <Button name="Sign Up" form={true} styles={{position: "absolute", right: "0px", top: "0px", display: step<3?"none":"inline-block"}} />  
                </div>
                

                <br/>
                <br/>
                <br/>
            </form>
        </div>
    )
}
export default Signup