import { useState } from "react"
import Button from "./components/Public/Button"
import { X } from "react-bootstrap-icons"
import Signin from "./components/Auth/Signin"
import Signup from "./components/Auth/Signup"
import ImageSlide from "./components/Public/ImageSlide"


const Auth = (props) => {
    const [p_a_vis, set_p_a_vis] = useState(false)
    const [signin, setSignin] = useState(true)

    const s_su_btn = () => {
        setSignin(false)
        set_p_a_vis(true)
    }

    const s_si_btn = () => {
        setSignin(true)
        set_p_a_vis(true)
    }

    const imageUrls = [
        "image/t3.jpg","image/t4.jpg","image/t5.jpg","image/t6.jpg","image/t7.jpg","image/t8.jpg","image/t9.jpg","image/t10.jpg","image/t11.jpg","image/t12.jpg","image/t13.jpg","image/t14.jpg","image/t15.jpg","image/t16.jpg","image/t17.jpg","image/t18.jpg","image/t19.jpg","image/t20.jpg","image/t21.jpg","image/t22.jpg"
    ];

    return (
        <>
            <div className="m h_btn_r" style={{zIndex:"999"}}>
                <Button name="Sign In" onclick={s_si_btn} />
                <Button name="Sign Up" onclick={s_su_btn}/>
            </div>
            <div className="auth">
                <div className="default_bg">
                    <p className="parag">
                    <span style={{textAlign: "center", display: "block"}}>Welcome to</span>
                    <br/>
                    <span style={{textAlign: "center", display: "block", marginTop: "-20px", color: "#12529f", fontWeight: "bolder", fontSize: "26px"}}>
                        WeLearn CLASSROOM
                    </span>

                    <b>About</b>
                    <br/>
                        WeLearn is a 24-hour teaching-learning-collaborative virtual classroom invented by team of instuctional technologists and programmer to provide a robust national curriculum based learning for O-Level, A-Level candidates as well as students of higher education:making learning possible beyond geographical time and space as well as compliment physical tutorial classes or at school learning activities: be it secondary school candidates(SSS 1-3/WAEC/UTME/PUTME) or for  undergraduates of higher education(Universities, Polytechnics and Colleges)
                        <br/>
                        <br/>
                        For O-level students, the innovative virtual platform provide key solutions to the following problems in the traditional classrooms/tutorials: lack of personalized learning experiences, lack of prompt formative evaluation database per module/topic learnt to track students' learning progress,  problem of unequal distribution of students’ access to experts facilitators in physical classrooms.
                        <br/>
                        <br/>
                        For higher education, the platform benefits lecturers and students inter-alia, by promoting convenient teaching-learning,  adaptable course outlines, CBT based quiz per module taught with programmed submission deadlines, digital script  marking/ grading platform, blended learning, bridge the gap between formal and informal education, enables  distance learning as to solve the problem of overcrowded classrooms, aids students' academic record management/access by students and lecturers at a click, promotes individualized and self paced learning.
                        <br/>
                        <br/>
                        With synchronous and asynchronous learning experiences, Welearn makes possible immersive and flexible learning for all categories of learners (regular, working class, adult, special learners, gifted or talented) who wants to accomplish their educational dreams by learning remotely with their digital devices any time, any day, anywhere.
                        <br/>
                        <br/>
                        Most inspiring and unthinkable feature of Welearn classroom is students' access to virtual tour of science laboratory, video practical sessions, practical manuals for SSCE/GCE practicals or alternative to practicals.  This feature provides support for students who are not familiar with science practicals' modalities, as well as creating an open learning platform for secondary schools who are not privileged to currently afford science practical laboratories  or access expert science practical facilitators.
                        <br/>
                        <br/>
                        Finally the online class feature is rich with teaching-learning tools and resources such as white board, videos, video notes, voice notes,  podcasts, pdf, notes, collaborative subject based chat rooms, Utube-links, images, post class test, topical CBT practice, CBT Mock, JAMB/SSCE syllabus, adaptable course outlines, and lots more. 
                        <br/>
                        <br/>
                        You see, the app is built with you in mind: irrespective of your capabilities, aptitude, limitations, past failures, previous learning experiences, learning ambitions, Success is now yours as "Welearn." Come get it! Sign up!
                    
                        <br/>
                        <br/>
                        Team
                        <br/>
                        WeLearn.

                    </p>
                    <div className="parag">
                        <b style={{fontSize: "18px", display: "block", textAlign: "center", color: "#12529f"}}>Students' Testmonials/Reviews</b>
                        <div style={{ maxWidth: "600px", height: "auto", margin: "auto", background: "#fff" }}>
                            <ImageSlide file={props.file} images={imageUrls} slideInterval={10000} />
                        </div>
                    </div>
                </div>
                <div style={{ display: !(props.winWidth<=699.9)?"block":props.winWidth<=699.9&&p_a_vis?"block":"none",}}>
                    <div className="default_bg auth_holder"  style={{position: "relative",}}>
                        <Button name={<X/>} error className="m" onclick={()=>{
                            set_p_a_vis(false)
                        }} styles={{position: "absolute", right: "16px"}} />
                            
                        {
                            signin
                            ?
                            <>
                            
                                <Signin file={props.file} api={props.api} setSignin={() => setSignin(!signin)}/>
                            </>
                            :
                            <>
                                <Signup file={props.file} api={props.api}  setSignin={() => setSignin(!signin)} />
                            </>
                        }

                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default Auth