// src/InstallPrompt.js
import React, { useState, useEffect } from 'react';
import Button from '../pages/components/Public/Button';

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [display, setDisplay] = useState(true)

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
    //   console.log('beforeinstallprompt event fired');
      e.preventDefault();
      setDeferredPrompt(e);
      setShowPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
        //   console.log('User accepted the install prompt');
        } else {
        //   console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowPrompt(false);
      });
    }
  };

  if (!showPrompt) return null;

  return (
    <div style={{
        position: "fixed",
        display: display ? "block" : "none",
        zIndex: "999999999999",
        bottom: "15px",
        width: "100%"
    }}>
        <div style={{
            textAlign: "center",
            background: "#12529f",
            width: "fit-content",
            margin: "auto",
            padding: "8px 10px",
            fontSize: "14px",
            color: "#fff",
            borderRadius: "12px"

        }}>
            Install Welearn App for a better experience 
            <div style={{
                textAlign: "right"
            }}>
                <Button name="Install" onclick={handleInstallClick} styles={{marginRight: "6px"}} success/>
                <Button name="Cancel" onclick={() => setDisplay(false)} error/>
            </div>
        </div>
    </div>
  );
};

export default InstallPrompt;
