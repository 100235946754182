import Dashboard from "../pages/components/Home/subpages/Dashboard"

const TutorPending = (props) => {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            overflow: "hidden"
        }}>
            {
                props.tutor.tutor_verified==="2"
                ?
                <>
                    <div style={{ background: "#12529f", color: "#fff", padding: "6px 12px", borderRadius: "8px", fontSize: "14px", textAlign: "center", margin: "8px auto", maxWidth: "800px" }}> 
                        Please, you will be contacted soon by our admin for verification and interview
                    </div>
                </>:
                ""                
            }
            {
                props.tutor.tutor_verified==="3"
                ?
                <div style={{width: "100%", padding: "4px 8px"}}>
                    <div style={{ background: "#28a745", color: "#fff", padding: "6px 12px", borderRadius: "8px", fontSize: "14px", textAlign: "center", margin: "8px auto", maxWidth: "800px" }}> 
                        You have been selected for an interview. <a style={{background: "inherit", color: "#fff"}} href="https://chat.whatsapp.com/DlOXmulV4jB1ZCnyENMO3n" target="_blank" rel="noreferrer" >Join facilitators' forum for notification on venue and date for  interview, training orientation and activation of your WeLearn teaching platform.</a> 
                    </div>
                </div>:
                ""        
            }
            
            <div style={{
                flex: "1",
            }}>
                <Dashboard api={props.api} file={props.file} winWidth={props.winWidth} />
            </div>
        </div>
    )
}

export default TutorPending