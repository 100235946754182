import { Link } from "react-router-dom";
import InputMethod from "../pages/components/Public/InputMethod";
import Policy from "./Policy";
import Button from "../pages/components/Public/Button";
import TimeTable from "../../src/assets/img/timetable.jpg";
import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";

const VerifyTutor = (props) => {
    const [selectedvideos, setSelectedvideos] = useState(null);
    const [display, setDisplay] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [upload_rate, setUpload_rate] = useState(0);

    const tutor_id = Cookies.get("mdjdiowdtutor") || "";

    const uploadVerVideo = async (e) => {
        e.preventDefault();
        if (!e.target.confirm_rules.checked) {
            return alert("You have to agree to the policy to you continue");
        }
        if (!selectedvideos) {
            return alert("Please choose video file");
        }
        setDisabled(true);

        const config = {
            onUploadProgress: function (progressEvent) {
                const { loaded, total } = progressEvent;
                setUpload_rate(0);

                let percent = Math.floor((loaded * 100) / total);
                if (percent < 100) {
                    setUpload_rate(percent);
                } else {
                    setUpload_rate(100);
                }
            }
        };

        let formData = new FormData();
        formData.append("authorization", "2dkn3KN38JJ*d!den_-dkDN");
        formData.append("run_type", "/tutor/request_verify");
        formData.append("tutor_id", tutor_id);
        formData.append("video", e.target.video.files[0]);

        try {
            const response = await axios.post(props.api, formData, config);
            try {
                if (!response.data.error) {
                    props.setTutorVerified(true);
                    props.setTutorPending(true);
                    // console.log(response.data)
                    setDisabled(false)
                } else {
                    alert(response.data.message);
                    setDisabled(false);
                }
                setUpload_rate(100);
            } catch { }
        } catch (err) {
            alert("Network error");
        }

    };

    const videoUpload = (event) => {
        const files = event.target.files[0];

        const allowedMimeTypes = ['video/mp4', 'video/webm', 'video/ogg', 'video/3gp'];

        if (!files) {
            setSelectedvideos(null)
            return
        }

        if (!allowedMimeTypes.includes(files.type)) {
            alert(`File must be a video of type mp4, webm, or ogg`);
            event.target.value = "";
            return;
        }

        const videoElement = document.createElement('video');
        videoElement.src = URL.createObjectURL(files);
        videoElement.onloadedmetadata = () => {
            if (videoElement.duration > 325) { // 325 seconds = 5 minutes and 25 seconds
                alert("Video duration exceeds the limit of 5 minutes and 25 seconds");
                event.target.value = "";
                return;
            }
            setSelectedvideos(files);
        };

    };


    return (
        <>
            <div className="tool_con">
                <div className="tool_holder default_bg">
                    <div className="tool_holder_header">
                        <h3 style={{ fontWeight: "bolder", textTransform: "uppercase", textAlign: "center" }}> Tutor Verification </h3>
                    </div>
                    <form onSubmit={uploadVerVideo}>
                        <div className="tool_holder_body">
                            <div style={{ textAlign: "center", margin: "14px 0px" }}>
                                <div className="form-group">
                                    <label htmlFor="_videos" style={{ cursor: "pointer", fontSize: "16px" }}>
                                        Upload sample video of your class
                                        <br/>
                                        <span style={{fontSize: "13px", fontStyle: "italic"}}>(Video duration should not exceeds the limit of 5 minutes and 25 seconds)</span>
                                    </label>
                                    <input
                                        type="file"
                                        onChange={videoUpload}
                                        accept="video/*"
                                        id="_videos"
                                        className="form-control"
                                        name="video"
                                    />
                                </div>
                                
                            </div>
                            <div className="sm_ele_grid" style={{ margin: "4px auto" }}>
                                <Button name="Tutor Policy" styles={{ textAlign: "center" }} onclick={() => setDisplay(true)} />

                                <a href={TimeTable} style={{
                                    margin: "4px auto",
                                    textDecoration: "none",
                                    border: "1px solid #12529f",
                                    display: "block",
                                    width: "100%",
                                    textAlign: "center",
                                    padding: "4px"
                                }} target="_blank" rel="noreferrer" download="timetable.jpg"> Download Time Table </a>
                            </div>
                            <InputMethod name="confirm_rules" label="I have read the tutor's policy and I agree" checklist style={{ border: "none" }} required />
                        </div>
                        <div className="tool_holder_footer">
                            Not your account? <Link to="/logout" style={{ textDecoration: "none" }}>Logout</Link>
                            {
                                disabled ?
                                    <div style={{ width: "100%", height: "fit-content", background: "rgba(18, 82, 159, .3)", position: "relative", borderRadius: "4px", overflow: "hidden", }} onClick={() => setDisabled(false)}>
                                        <div style={{ minWidth: "25px", width: upload_rate + "%", height: "30px", position: "relative", background: "rgba(18, 82, 159, 1)", transition: "all .3s" }}>
                                            <span style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", fontSize: "12px", transition: "all .3s", color: "#fff" }}>
                                                {upload_rate}%
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    <Button name="Request Verification" form />
                            }

                        </div>
                    </form>
                </div>
            </div>
            {display ? <Policy display={display} setDisplay={setDisplay} /> : ""}
        </>
    );
}

export default VerifyTutor;
